@use 'typography';
@use 'colors';

/*!
 * Copyright 2012 Lucid
 * Multiselect v1.0.0
 */

.ms-container {
  background: transparent url('img/switch.png') no-repeat 234px 200px;
}

.ms-container:after {
  clear: both;
  content: '.';
  display: block;
  font-size: 0;
  height: 0;
  line-height: 0;
  min-height: 0;
  visibility: hidden;
}

.ms-container .ms-selectable,
.ms-container .ms-selection {
  background: none;
  color: #555;
  float: left;
}

.ms-selection {
  margin-top: 34px;
}

.ms-container .ms-list {
  background: #fff;
  border: 1px solid #ccc;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 12px 10px -15px rgba(0, 0, 0, 0.8); /* drop shadow applied to inside field */
  -moz-box-shadow: inset 0 12px 10px -15px rgba(0, 0, 0, 0.8);
  box-shadow: inset 0 12px 10px -15px rgba(0, 0, 0, 0.8);
  -webkit-transition: border linear 0.2s, box-shadow linear 0.2s;
  -moz-transition: border linear 0.2s, box-shadow linear 0.2s;
  -ms-transition: border linear 0.2s, box-shadow linear 0.2s;
  -o-transition: border linear 0.2s, box-shadow linear 0.2s;
  transition: border linear 0.2s, box-shadow linear 0.2s;
}

.ms-selected {
  display: none;
}

.ms-container .ms-selectable {
  margin-right: 54px;
}

.ms-selectable input[type='text'] {
  width: 218px;
}

.ms-container .ms-list.ms-focus {
  outline: 0;
  outline: thin dotted \9;
}

.ms-container ul {
  font-family: typography.$font-family;
  list-style-type: none;
  margin: 0;
}

.ms-container ul.ms-list {
  height: 265px;
  overflow-y: auto;
  padding: 4px 0;
  width: 230px;
}

.ms-container .ms-selectable li.ms-elem-selectable,
.ms-container .ms-selection li.ms-selected {
  color: #222;
  font-size: 14px;
  padding: 6px 15px 6px 15px;
}

.ms-container .ms-selectable li.disabled,
.ms-container .ms-selection li.disabled {
  background-color: #eee;
  color: #aaa;
}

.ms-container .ms-optgroup-label {
  color: #999;
  cursor: pointer;
  font-size: 10px;
  font-weight: 700;
  padding: 10px 0 0 15px;
  text-transform: uppercase;
}

.ms-container li.ms-elem-selectable:not(.disabled).ms-hover,
.ms-container .ms-selection li:not(.disabled).ms-hover {
  background-color: #eee;
  color: #222;
  cursor: pointer;
  text-decoration: none;
}

.ms-select-options {
  margin-top: 5px;
}

.ms-custom-header {
  font-family: typography.$font-family;
  font-size: 18px;
  margin: 7px 0 7px 2px;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
}

.ms-custom-footer {
  color: colors.$gray700;
  font-size: 11px;
  margin-left: 2px;
  margin-right: 8px;
  text-decoration: none;
}

.ms-custom-footer a:link {
  text-decoration: none;
}

.ms-custom-footer a:hover {
  color: #222;
  text-decoration: underline;
}
