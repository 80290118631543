@use "bootstrap-variables";
@use "mixins";

// LARGE DESKTOP & UP
// ------------------

@media (min-width: 1200px) {

  // Fixed grid
  @include mixins.gridCore(bootstrap-variables.$gridColumnWidthLarge, bootstrap-variables.$gridGutterWidthLarge);

  // Fluid grid
  @include mixins.gridFluid(bootstrap-variables.$fluidGridColumnWidthLarge, bootstrap-variables.$fluidGridGutterWidthLarge);

  // Input grid
  @include mixins.gridInput(bootstrap-variables.$gridColumnWidthLarge, bootstrap-variables.$gridGutterWidthLarge);

  // Thumbnails
  .thumbnails {
    margin-left: -30px;
  }
  .thumbnails > li {
    margin-left: 30px;
  }
  .row-fluid .thumbnails {
    margin-left: 0;
  }

}