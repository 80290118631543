@use 'colors';
@use "bootstrap-variables";

#toast-container {
  position: fixed;
  z-index: 999999;

  * {
    box-sizing: border-box;
  }

  .toast {
    background-color: rgba(colors.$gray700, 0.9);
    background-position: 15px center;
    background-repeat: no-repeat;
    box-shadow: 0 8px 8px 0 rgba(colors.$gray900, 0.24), 0 0 8px 0 rgba(colors.$gray900, 0.12);
    color: bootstrap-variables.$white;
    font-size: 14px;
    margin-bottom: 12px;
    overflow: hidden;
    padding: 24px 60px 24px;
    position: relative;
    width: 512px;

    &:hover {
      cursor: pointer;
      opacity: 1;
    }

    &::before {
      font-family: "Font Awesome 5 Pro";
      font-size: 18px;
      left: 24px;
      position: absolute;
      top: calc(50% - 12px);
    }
  }

  .toast-info:before {
    color: colors.$gray700;
    content: '\f129';
  }

  .toast-success:before {
    color: colors.$green300;
    content: '\f00c';
  }

  .toast-error:before {
    color: colors.$red300;
    content: '\f00d';
  }

  .toast-warning:before {
    color: colors.$orange400;
    content: '\f071';
  }
}

.toast-top-right {
  right: 24px;
  top: 76px;
}
