@use "bootstrap-variables";
@use "colors";
@use "mixins";

// TOOLTIP
// ------=

.tooltip {
  position: absolute;
  z-index: bootstrap-variables.$zindexTooltip;
  display: block;
  visibility: visible;
  padding: 5px;
  font-size: 11px;
  @include mixins.opacity(0);
  &.in     { @include mixins.opacity(0.8); }
  &.top    { margin-top:  -2px; }
  &.right  { margin-left:  2px; }
  &.bottom { margin-top:   2px; }
  &.left   { margin-left: -2px; }
  &.top .tooltip-arrow    { @include mixins.popoverArrowTop(); }
  &.left .tooltip-arrow   { @include mixins.popoverArrowLeft(); }
  &.bottom .tooltip-arrow { @include mixins.popoverArrowBottom(); }
  &.right .tooltip-arrow  { @include mixins.popoverArrowRight(); }
}
.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: colors.$white;
  text-align: center;
  text-decoration: none;
  background-color: colors.$black;
  @include mixins.border-radius(4px);
}
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
}
