@use 'colors';
@use 'theme';

.navbar {
  background-color: white;
  display: block;
  height: theme.$navbar-height;
  padding-left: theme.$sidebar-width + 21px;
  padding-right: 21px;
  border-bottom: 1px solid colors.$gray400;

  font-family: 'Roboto', 'helvetica neue', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased; // subpixel-antialiasing messes up our font weights
  -webkit-font-feature-settings: 'kern' 1, 'liga' 1; // ensures we use available opentype features

  form {
    margin: 0; // hack to override bootstrap global
  }

  img {
    max-width: 100%;
    vertical-align: middle;
    border: 0;
  }

  > * {
    float: left;
    height: theme.$navbar-height;
    line-height: theme.$navbar-height;
    vertical-align: middle;
  }

  @media (max-width: 767px) {
    left: 0;
  }

  @media (max-width: 580px) {
    .select2-container {
      width: 150px !important;
    }
  }

  @media (max-width: 520px) {
    .select2-container {
      width: 100px !important;
    }
  }
}

.navbar-chromeless {
  display: flex;
  justify-content: space-between;
  padding-left: 24px;
}

.navbar-fixed {
  border-bottom: 1px solid colors.$gray400;
  left: theme.$sidebar-width;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 5;
}

.navbar-logo-custom {
  padding-right: 21px;
}

.navbar-logo {
  padding-right: 21px;
  width: theme.$navbar-logo-width;

  img {
    height: 25px;
  }
}

.navbar-breadcrumbs {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - #{theme.$navbar-logo-width + theme.$navbar-right-width} - 32px);
  line-height: 55px;

  @media (max-width: 819px) {
    display: none;
  }
}

.navbar-right {
  position: absolute;
  right: 21px;
  top: 0;
  width: theme.$navbar-right-width;
  > * {
    display: block;
    float: right;
  }
}

.navbar-notifications {
  display: block;
  padding: 0 7px;
  position: relative;
  white-space: nowrap;

  > img.navbar-notif-icon {
    &:hover {
      opacity: 0.5;
    }
  }

  > svg.navbar-notif-icon {
    vertical-align: middle;

    &:hover {
      opacity: 0.5;
    }
  }
}

.navbar-notifications--active {
  > img.navbar-notif-icon {
    opacity: 0.5;
  }
}

.navbar-notifications-count {
  background: colors.$red300;
  border: 2px white solid;
  border-radius: 15px;
  box-sizing: border-box;
  color: white;
  font-size: 10px;
  height: 18px;
  line-height: 10px;
  min-width: 18px;
  padding: 2px 4px;
  position: absolute;
  right: 2px;
  text-align: center;
  top: 8px;
  z-index: 3;
}

.navbar-orgPicker {
  display: block;
  padding: 1px 0 0 21px;
  width: 200px;

  select {
    height: 28px;
    margin-bottom: 0;
    padding: 0 0 0 5px;
    width: 200px;
  }

  p {
    margin-bottom: 2px;
  }
}

.navbar-dropdown {
  display: block;
  padding: 0 7px;

  img.navbar-profile-icon {
    border-radius: 12px;
    opacity: 0.75;

    &:hover {
      opacity: 1;
    }
  }
}

.navbar-separator {
  padding: 5px 21px;

  &:before {
    background: colors.$gray300;
    content: ' ';
    display: block;
    height: 42px;
    width: 1px;
  }
}

.navbar-org-logo img {
  height: 30px;
  max-width: 150px;
}

.intercom-messenger-frame {
  top: 50px !important;
}
