@use "colors";

#addReading {
  margin-bottom: 20px;
}

.manualReading {
  background-color: #ecebe8;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 4px;
  padding: 12px;
  position: relative;

  .manualReadingRange {
    color: colors.$gray700;
    font-size: 120%;
    font-weight: 200;
    letter-spacing: 6px;
  }

  .manualReadingDate {
    display: inline-block;
    letter-spacing: normal;
    vertical-align: top;

    .readingTime {
      display: block;
      font-size: 75%;
    }
  }

  .manualReadingValue {
    font-size: 300%;
    font-weight: 200;
    margin-top: 10px;
    vertical-align: baseline;

    .manualReadingUnits {
      font-size: 25px;
      font-weight: 200;
      margin-right: 20px;
      vertical-align: baseline;
    }
  }
}

@media all and (max-width: 680px) {
  .manualReading {
    .manualReadingDate .readingTime {
      display: inline;
    }

    .manualReadingButtons {
      .delete {
        margin-left: 20px;
      }
    }
  }
}

@media all and (max-width: 820px) {
  .manualReading {
    .manualReadingButtons {
      float: right;
      margin-top: 4px;

      .delete {
        margin-left: 15px;
      }
    }
  }
}

@media all and (min-width: 821px) {
  .manualReading {
    .manualReadingButtons {
      display: none;
      float: right;

      .edit {
        position: absolute;
        right: 12px;
        top: 12px;
      }

      .delete {
        bottom: 12px;
        position: absolute;
        right: 12px;
      }
    }

    &:hover .manualReadingButtons {
      display: block;
    }
  }
}
