@use "bootstrap-variables";
@use "colors";
@use "mixins";

// CLOSE ICONS
// -----------

.close {
  float: right;
  font-size: 20px;
  font-weight: bold;
  line-height: bootstrap-variables.$baseLineHeight;
  color: colors.$black;
  text-shadow: 0 1px 0 rgba(255,255,255,1);
  @include mixins.opacity(0.2);
  &:hover {
    color: colors.$black;
    text-decoration: none;
    cursor: pointer;
    @include mixins.opacity(0.4);
  }
}

// Additional properties for button version
// iOS requires the button element instead of an anchor tag.
// If you want the anchor version, it requires `href="#"`.
button.close {
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}