// Separate file to ensure that this doesn't get lost and forgotten in main.scss

@use 'colors';
@use 'theme';
@use "sizes";

#content {
  bottom: 0;
  left: sizes.$sidebar-width;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: theme.$navbar-height + 1;
}
