.dropdown-menu {
  .header {
    clear: both;
    color: #999;
    display: block;
    font-size: 11px;
    font-weight: bold;
    line-height: 18px;
    padding: 4px 12px;
    text-transform: uppercase;
    white-space: nowrap;
  }

  .row-fluid [class*='span'] {
    overflow: hidden;
  }
}

.dropdown-menu.multi-column .dropdown-menu {
  border: none !important;
  box-shadow: none !important;
  display: block !important;
  margin: 0 !important;
  min-width: 138px;
  position: static !important;
}

#type_filters .dropdown-menu .dropdown-menu a {
  white-space: normal;
}
$btn-col-width:175px;

.btn-group {
  ul.multi-column.cols-1 {
    min-width: $btn-col-width;
  }

  ul.multi-column.cols-2 {
    min-width: $btn-col-width*2;
  }

  ul.multi-column.cols-3 {
    min-width: $btn-col-width*3;
  }

  ul.multi-column.cols-4 {
    min-width: $btn-col-width*4;
  }
}
