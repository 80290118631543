@use 'colors';
@use "bootstrap-variables";

input.Checkbox[type='checkbox'],
.ltable td > input[type='checkbox'] {
  display: none;
}

input.Checkbox[type='checkbox'] + label,
.col-check > i {
  cursor: pointer;
  font-weight: normal;
  position: relative;
}

input.Checkbox[type='checkbox'] + label:before,
.ltable td > input[type='checkbox'] + label:before,
.col-check > i {
  background: bootstrap-variables.$white;
  border-color: colors.$gray400;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  content: '';
  cursor: pointer;
  display: inline-block;
  height: 18px;
  vertical-align: middle;
  width: 18px;
}

input.Checkbox[type='checkbox'] + label:before {
  margin: 0 8px 0 0;
}

input.Checkbox[type='checkbox'] + label:hover:before,
.ltable td > input[type='checkbox'] + label:hover:before,
.col-check > i:hover:before {
  box-shadow:
    inset 0 0 2px 0 rgba(17, 25, 31, 0.12),
    inset 0 2px 2px 0 rgba(17, 25, 31, 0.24);
}

input.Checkbox[type='checkbox'][disabled] + label {
  color: colors.$gray400;
}

input.Checkbox[type='checkbox'][disabled] + label:hover:before {
  box-shadow: none;
}

input.Checkbox[type='checkbox']:checked + label:before,
.ltable td > input[type='checkbox']:checked + label:before,
.col-check > i.col-check-all {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjIycHgiIGhlaWdodD0iMjJweCIgdmlld0JveD0iMCAwIDIyIDIyIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnNrZXRjaD0iaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoL25zIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggMy4zICgxMTk3MCkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+QXJ0Ym9hcmQgMSBDb3B5IDExPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc2tldGNoOnR5cGU9Ik1TUGFnZSI+CiAgICAgICAgPGcgaWQ9IkFydGJvYXJkLTEtQ29weS0xMSIgc2tldGNoOnR5cGU9Ik1TQXJ0Ym9hcmRHcm91cCIgc3Ryb2tlPSIjOEJDNTNGIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik03LDExIEwxMCwxNCBMMTUsOCIgaWQ9IkltcG9ydGVkLUxheWVycyIgc2tldGNoOnR5cGU9Ik1TU2hhcGVHcm91cCI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==');
  background-position: center;
  background-repeat: no-repeat;
}

input.Checkbox[type='checkbox'][data-indeterminate] + label:before {
  color: colors.$green300;
  content: '\2014';
  font-size: 16px;
  font-weight: bold;
}

.col-check > i.col-check-some:before {
  background-color: colors.$green300;
  border-radius: 2px;
  content: '';
  display: block;
  height: 2px;
  left: 3px;
  position: absolute;
  top: 9px;
  width: 12px;
}
