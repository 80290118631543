@use 'colors';

.message,
[class='message--success'],
[class='message--warning'] {
  background-color: colors.$yellow500;
  color: white;
  font-weight: 600;
  line-height: 1.618;
  padding: 7px 21px;
  text-align: center;
}

.messages a {
  text-decoration:underline;
}

.message a,
[class='message--success'] a,
[class='message--warning'] a {
  color: white;
}

.message a:hover,
[class='message--success'] a:hover,
[class='message--warning'] a:hover {
  color: white;
}

.message--success {
  background-color: colors.$green300;
}

.message--warning {
  background-color: colors.$red300;
}

.message--info + .message--info {
  margin-top: 7px;
}

.message--info.light-gray-background {
  .message-content {
    background-color: colors.$gray200;
  }
}

.message--info {
  background-color: white;
  color: black;
  display: grid;
  font-size: 14px;
  font-weight: normal;
  grid-template-columns: 8px 1fr;
  text-align: left;

  &.message--profile {
    margin-bottom: 24px;
  }

  & {
    // IE11 support
    display: -ms-grid;
    -ms-grid-columns: 8px 1fr;
  }

  .message-shadow {
    background-color: colors.$gray350;
    grid-column: 1;
    width: 8px;

    // IE11 support
    -ms-grid-column: 1;
  }

  .message-content {
    display: grid;
    grid-column: 2;
    grid-template-columns: 38px 1fr 25px;
    padding: 24px;
    padding-left: 20px;
    padding-right: 20px;

    // IE11 support
    display: -ms-grid;
    -ms-grid-column: 2;
    -ms-grid-columns: 38px 1fr;

    ul {
      list-style: disc,
    }
  }

  .message-text {
    grid-column: 2;

    // IE11 support
    -ms-grid-column: 2;
  }

  .message-dismiss {
    cursor: pointer;
    grid-column: 3;
    height: 20px;
  }

  .svg-symbol {
    grid-column: 1;
    margin-right: 14px;
    margin-top: -2px;

    // IE11 support
    -ms-grid-column: 1;
  }

  a {
    text-decoration: underline;
  }
}

form .message--info {
  .message-content {
    background-color: colors.$gray100;
  }
}
