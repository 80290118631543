@use 'sass:color';
// Naming convention adopted from https://github.com/suitcss/suit/blob/master/doc/naming-conventions.md#is-stateOfComponent
// except:
//
// * lowercase
// * uses [class^='']
//
// (!) NOTE: DEPRECATE CLASS WHEN BUTTONS HAVE TRANSITIONED TO REACT

@use 'colors';

@mixin filled-button($color, $hovercolor, $insetcolor) {
  background-color: $color;
  border-color: $color;
  color: white;

  &:hover,
  &:focus {
    background-color: $hovercolor;
    box-shadow: inset 0 -3px 0 0 $insetcolor;
    color: white;
  }

  &:active {
    background-color: $hovercolor;
    box-shadow: inset 0 0 2px 0 rgba(17, 25, 31, 0.12), inset 0 2px 2px 0 rgba(17, 25, 31, 0.24);
    color: white;
  }

  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active {
      background-color: $color;
      border-color: $color;
    }
  }
}

@mixin outline-button($color) {
  background-color: transparent;
  border: 1px solid $color;
  color: $color;

  &:hover,
  &:focus {
    background-color: transparent;
    border-color: color.adjust($color, $lightness: 15%);
    color: color.adjust($color, $lightness: 15%);
    box-shadow: inset 0 -3px 0 0 color.adjust($color, $lightness: 15%);
  }

  &:active {
    background-color: transparent;
    border-color: color.adjust($color, $lightness: -5%);
    color: color.adjust($color, $lightness: -5%);
  }

  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      border-color: $color;
      color: $color;
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}

// Base styles for all buttons.
.button, // Included so that there can be a default button
[class^='button--'],
[class*=' button--'] {
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  height: 38px;
  line-height: 38px;
  margin-bottom: 0; // For <input class='Button'> Button
  outline: 0;
  padding: 0 22px;
  -webkit-tap-highlight-color: transparent; // Gets rid of tap active state
  text-decoration: none !important;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;

  // Default button is blue
  @include filled-button(colors.$blue300, colors.$blue400, colors.$blue500);

  // TO DO: Remove when we can remove the font-awesome hacks in main.scss
  // If we still need to style <i> inside button, use .button-i instead of cascade
  i {
    font-size: 15px;
    margin-right: 5px;
    vertical-align: 0 !important;
  }

  .add-icon {
    bottom: 1px;
    height: 18px;
    position: relative;
    right: 3px;
    width: 18px;
  }
}

.button--success {
  @include filled-button(colors.$green300, colors.$green400, colors.$green500);
}

.button--danger {
  @include filled-button(colors.$red300, colors.$red400, colors.$red500);
}

.button--cancel {
  @include filled-button(colors.$gray500, colors.$gray600, colors.$gray700);
}

.button--grey {
  @include filled-button(colors.$gray600, colors.$gray600, colors.$gray600);
}

.button--white {
  @include filled-button(white, white, white);

  & {
    border: 1px solid colors.$gray400;
    color: colors.$gray600;
  }

  &:hover,
  &:focus,
  &:active {
    color: colors.$gray600;
  }
}

.button--edit {
  @include filled-button(colors.$blue300, colors.$blue400, colors.$blue500);
}

.button--warning {
  @include filled-button(colors.$orange300, colors.$orange400, colors.$orange500);
}

.button--neutral {
  @include outline-button(colors.$gray600);
}

// TO DO: Size relative to base line height as part of #11710
.button--small {
  font-size: 12px;
  height: 30px;
  line-height: 30px;
  padding: 0 19px;
}

.button--large {
  font-size: 18px;
  height: 50px;
  line-height: 50px;
  padding: 0 25px;
}

.button--block {
  display: block;
  width: 100%;
}
