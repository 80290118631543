@use "mixins";

// COMPONENT ANIMATIONS
// --------------------

.fade {
  opacity: 0;
  @include mixins.transition(opacity .15s linear);
  &.in {
    opacity: 1;
  }
}

.collapse {
  position:relative;
  height: 0;
  overflow:hidden;
  @include mixins.transition(height .35s ease);
  &.in {
    height: auto;
  }
}