/*!
 * Copyright 2012 Lucid
 * Responsive Overwrites and Additions, v1.0.0 re: v2.0.4
 */

@media (max-width: 767px) { /* big phone landscape */
  body {
    padding-top: 0;
  }

  .pull-left,
  .pull-right {
    float: left;
  }

  .pull-right.popover_top,
  .pull-right-override {
    float: right;
  }

  .nav-pills-spacer {
    margin-right: 15px;
  }

  .menu-inheader {
    margin-top: -25px;
  }

  .inheader-spacer {
    height: 40px;
  }

  .inheader-spacer-buttons {
    height: 60px;
  }

  img.app,
  img.facility,
  img.service {
    width: 100%;
  }

  .group-form {
    line-height: 40px;
  }

  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'],
  input[type='search'],
  input[type='tel'],
  input[type='color'],
  .uneditable-input {
    font-size: 15px;
    min-height: 38px;
    padding: 0 10px;
  }

  form select {
    padding: 6px;
  }

  .textarea-small {
    height: 100px;
  }

  .date-range {
    font-size: 15px;
    padding: 5px;
    padding-bottom: 0;
    padding-top: 0;
  }

  /* stylelint-disable selector-type-no-unknown */
  timestamp {
    /* stylelint-enable */
    display: block;
  }

  .form-actions {
    text-align: center;
  }

  .control-group.stepwise {
    margin-left: 0;
  }

  .pagination-string {
    margin-bottom: 70px;
  }

  .pagination-recordlength {
    margin-bottom: 35px;
  }

  .action-bar {
    margin: 0 auto 20px auto;
  }

  .alert-note {
    margin-bottom: 50px;
  }

  .steps {
    margin-left: 0;
  }

  .spot {
    margin-left: 15px;
    margin-top: -15px;
  }

  #svg,
  #chartContainer {
    height: 327px;
    width: 726px;
  }
}

@media (min-width: 768px) { /* larger than phone  */
  .no-label .controls {
    margin-left: 0;
  }

  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'],
  input[type='search'],
  input[type='tel'],
  input[type='color'],
  .uneditable-input {
    line-height: 1.618;
    padding: 8px 8px;
  }
}

@media (min-width: 768px) and (max-width: 979px) { /* tablet portrait */
  body {
    padding-top: 0;
  }

  h3 {
    line-height: 16px;
  }

  .inside-cloud {
    padding: 15px 15px 15px 15px;
  }

  .action-bar {
    margin: -18px auto 28px auto;
  }

  .alert-note-text {
    line-height: 14px;
    margin-bottom: 10px;
  }

  .time-dropdown-adjacent {
    margin-left: 0;
    margin-right: 25px;
    width: 90px;
  }

  #svg,
  #chartContainer {
    height: 327px;
    width: 726px;
  }

  hr.padded {
    margin-bottom: 18px;
  }
}

@media (max-width: 480px) { /* small phone landscape */
  .inheader-spacer-short {
    height: 0;
  }

  .notify-bar-item {
    margin-bottom: 17px;
  }

  .control-plaintext {
    margin-left: 0;
    padding: 0;
    text-align: left;
  }

  .description-prepend {
    line-height: 45px;
  }

  /* stylelint-disable selector-type-no-unknown */
  .table-graph-summary h1 large {
    /* stylelint-enable */
    font-size: 32px;
  }

  /* stylelint-disable selector-type-no-unknown */
  .table-graph-summary h1 medium {
    /* stylelint-enable */
    font-size: 18px;
  }

  .table-graph-summary h1 small {
    line-height: 13px;
  }

  .table-graph-summary-top {
    margin-bottom: 0;
  }

  div.dataTables_info {
    margin-bottom: 20px;
  }

  div.dataTables_paginate {
    margin-bottom: 5px;
  }

  #svg,
  #chartContainer {
    width: 100%;
  }

  hr.padded {
    margin-bottom: 18px;
  }
}

@media (min-width: 1200px) {
  /* big desktop */
}

@media (max-width: 979px) {
  #svg,
  #chartContainer {
    height: 327px;
    width: 726px;
  }

  hr.padded {
    margin-bottom: 18px;
  }

  .nav-collapse .dropdown-menu h3,
  .nav-collapse .dropdown-menu .atOrganization {
    padding: 6px 15px;
  }

  .nav-collapse .dropdown-menu .atOrganization {
    color: #999;
  }
}

@media (max-width: 767px) { /* big phone landscape */
  #svg,
  #chartContainer {
    width: 100%;
  }
}

@media (min-width: 980px) and (max-width: 1200px) { /* tablet landscape */
  .checkbox-group.trailing {
    margin-top: -5px;
  }

  .time-dropdown {
    float: left;
  }

  .time-dropdown-adjacent {
    float: left;
    margin-left: 0;
    margin-right: 35px;
    width: 100px;
  }

  #svg,
  #chartContainer {
    height: 423px;
    width: 940px;
  }
}
