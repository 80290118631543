@use 'colors';
@use "bootstrap-variables";

.IntroCard,
[class^='IntroCard--'],
[class*=' IntroCard--'] {
  background-color: bootstrap-variables.$white;
  border: 1px solid bootstrap-variables.$white;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(17, 25, 31, 0.24), 0 0 2px 0 rgba(17, 25, 31, 0.12);
  margin: 23px auto;
  max-width: 100%;
  padding-bottom: 16px;
  width: 368px;

  > div {
    height: 260px;
    margin: 10px 26px 10px 10px;
    width: calc(100% - (2 * 10px));
  }

  > form {
    margin: 0;

    button {
      margin-left: 10px;
    }
  }

  h3 {
    font-size: 19px;
    margin: 28px 0 0 10px;
  }

  p {
    margin: 9px 10px 21px;
  }
}

.IntroCard {
  text-align: center;
}

.IntroCard--horizontal {
  min-height: 200px;
  max-width: 600px;
  min-width: 368px;
  padding: 0;
  width: 50%;

  > div {
    background: center center no-repeat;
    background-size: cover;
    float: left;
    height: 180px;
    overflow: hidden;
    width: 50%;
  }

  @media (max-width: 480px) {
    min-width: calc(100% - (2 * 15px));
    text-align: center;

    > div {
      float: none;
      width: calc(100% - (2 * 10px));
    }

    h3,
    a {
      margin: 10px;
    }
  }
}
