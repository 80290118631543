@use 'colors';

.ContainerHeader nav a {
  color: colors.$gray900;
  display: inline-block;
  font-size: 14px;
  margin: 0 7px -4px 7px;
  padding: 8px 12px;

  &:first-child {
    margin-left: 24px;
  }

  &:hover,
  &:focus {
    color: colors.$blue300;
    outline: none;
    text-decoration: none;
  }

  &.active {
    border-bottom: 4px solid colors.$blue300;
    color: colors.$gray900;
    font-weight: 700;
  }

  &.notify:before {
    color: colors.$red300;
    content: '\2022';
    font-size: 20px;
    line-height: 0;
    position: relative;
    top: 3px;
  }
}

@media (min-width: 1718px) {
  .ContainerHeader nav a {
    &:first-child {
      margin-left: 0;
    }
  }
}

// Ensures proper alignment with .page-header
.subnav-button-container {
  margin-top: 30px;
}
