#well-upload-container {
  background: #F9F9F9;
  border: 2px dashed #BAC6CC;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 15px;
  text-align: center;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.well-upload {
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  min-height: 20px;
  padding: 19px;
}

#upload-drop-area {
  background: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  bottom: 30px;
  left: 30px;
  position: fixed;
  right: 30px;
  text-align: center;
  top: 101px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  z-index: 1000;
}

.upload-instructions {
  color: #999;
  font-size: 20px;
  font-weight: 200;
  margin-bottom: 5px;
}

.upload-drop-curtain {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: 50px;
}

.menu-inheader {
  margin-bottom: 20px;
  margin-top: 0;
}

.uploadClickable {
  margin-bottom: 6px;
  margin-left: 5px;
}

.progressContainer {
  border: 1px solid black;
  display: none;
  height: 20px;
  margin: 0 auto;
  width: 40%;
}

.progressBar {
  background-color: rgb(167, 167, 192);
  height: 20px;
  width: 0%;
}
