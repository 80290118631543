@use 'colors';

body {
  background-color: colors.$blue500;
  color: white;
}

.error_container {
  padding-top: 100px;
  text-align: center;

  h1 {
    font-size: 36px;
    font-weight: 300;
    margin-bottom: 1em;
  }

  h2 {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 2em;
  }
}

.error {
  display: inline-block;
}

.error_footer {
  margin-top: 30px;
  text-align: center;
}

.error_image {
  height: 173px;
  margin-bottom: 40px;
}

.error_back {
  margin-bottom: 40px;
}

.error_footer_image {
  width: 151px;
}
