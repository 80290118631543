/* Atomic utility classes. One rule per class */

.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-nowrap {
  white-space: nowrap;
}

.u-relative {
  position: relative;
}

.u-text-center {
  text-align: center;
}

.u-cursor-default {
  cursor: default;
}

.u-cursor-pointer {
  cursor: pointer;
}

.u-cursor-move {
  cursor: move;
}
