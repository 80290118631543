@use "bootstrap-variables";

@use 'sass:color';
@use 'sass:math';

// Mixins.less
// Snippets of reusable CSS to develop faster and keep code readable
// -----------------------------------------------------------------


// UTILITY MIXINS
// --------------------------------------------------

// Clearfix
// --------
// For clearing floats like a boss h5bp.com/q
@mixin clearfix() {
  &:before,
  &:after {
    display: table;
    content: "";
  }
  &:after {
    clear: both;
  }
}
.clearfix { @include clearfix(); }

// Webkit-style focus
// ------------------
@mixin tab-focus() {
  // Default
  outline: thin dotted #333;
  // Webkit
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

// Center-align a block level element
// ----------------------------------
@mixin center-block() {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// Sizing shortcuts
// -------------------------
@mixin size($height, $width) {
  width: $width;
  height: $height;
}
@mixin square($size) {
  @include size($size, $size);
}

// Placeholder text
// -------------------------
@mixin placeholder($color: bootstrap-variables.$placeholderText) {
  &:-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color: $color;
  }
}

// Sass doesn't support using & at base level, so we need a special root placeholder mixin.
@mixin rootPlaceholder($color: bootstrap-variables.$placeholderText) {
  :-moz-placeholder {
    color: $color;
  }
  :-ms-input-placeholder {
    color: $color;
  }
  ::-webkit-input-placeholder {
    color: $color;
  }
}

// Text overflow
// ------------------------
@mixin text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// CSS image replacement
// -------------------------
// Source: https://github.com/h5bp/html5-boilerplate/commit/aa0396eae757
@mixin hide-text() {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}


// FONTS
// --------------------------------------------------
@mixin font-family-serif() {
  font-family: bootstrap-variables.$serifFontFamily;
}
@mixin font-family-sans-serif() {
  font-family: bootstrap-variables.$sansFontFamily;
}
@mixin font-family-monospace() {
  font-family: bootstrap-variables.$monoFontFamily;
}
@mixin font-shorthand($size: bootstrap-variables.$baseFontSize, $weight: normal, $lineHeight: bootstrap-variables.$baseLineHeight) {
  font-size: $size;
  font-weight: $weight;
  line-height: $lineHeight;
}
@mixin font-serif($size: bootstrap-variables.$baseFontSize, $weight: normal, $lineHeight: bootstrap-variables.$baseLineHeight) {
  @include font-family-serif();
  @include font-shorthand($size, $weight, $lineHeight);
}
@mixin font-sans-serif($size: bootstrap-variables.$baseFontSize, $weight: normal, $lineHeight: bootstrap-variables.$baseLineHeight) {
  @include font-family-sans-serif();
  @include font-shorthand($size, $weight, $lineHeight);
}
@mixin font-monospace($size: bootstrap-variables.$baseFontSize, $weight: normal, $lineHeight: bootstrap-variables.$baseLineHeight) {
  @include font-family-monospace();
  @include font-shorthand($size, $weight, $lineHeight);
}



// FORMS
// --------------------------------------------------

@mixin input-block-level() {
  display: block;
  width: 100%;
  min-height: 28px; // Make inputs at least the height of their button counterpart
  @include box-sizing(border-box); // Makes inputs behave like true block-level elements
}


// Mixin for form field states
@mixin formFieldState($textColor: #555, $borderColor: #ccc, $backgroundColor: #f5f5f5) {
  // Set the text color
  > label, .help-block, .help-inline {
    color: $textColor;
  }
  // Style inputs accordingly
  .checkbox, .radio, input, select, textarea {
    color: $textColor;
    border-color: $borderColor;
    &:focus {
      border-color: color.adjust($borderColor, $lightness: -10%);
      @include box-shadow(0 0 6px color.adjust($borderColor, $lightness: 20%));
    }
  }
  // Give a small background color for input-prepend/-append
  .input-prepend .add-on, .input-append .add-on {
    color: $textColor;
    background-color: $backgroundColor;
    border-color: $textColor;
  }
}


// CSS3 PROPERTIES
// --------------------------------------------------

// Border Radius
@mixin border-radius($radius) {
  border-radius: $radius;
}

// Drop shadows
@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
     -moz-box-shadow: $shadow;
          box-shadow: $shadow;
}

// Transitions
@mixin transition($transition) {
  -webkit-transition: $transition;
     -moz-transition: $transition;
      -ms-transition: $transition;
       -o-transition: $transition;
          transition: $transition;
}

// Transformations
@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
     -moz-transform: rotate($degrees);
      -ms-transform: rotate($degrees);
       -o-transform: rotate($degrees);
          transform: rotate($degrees);
}
@mixin scale($ratio) {
  -webkit-transform: scale($ratio);
     -moz-transform: scale($ratio);
      -ms-transform: scale($ratio);
       -o-transform: scale($ratio);
          transform: scale($ratio);
}
@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
     -moz-transform: translate($x, $y);
      -ms-transform: translate($x, $y);
       -o-transform: translate($x, $y);
          transform: translate($x, $y);
}

@mixin skew($x, $y) {
  -webkit-transform: skew($x, $y);
     -moz-transform: skew($x, $y);
      -ms-transform: skew($x, $y);
       -o-transform: skew($x, $y);
          transform: skew($x, $y);
}

@mixin translate3d($x, $y, $z) {
  -webkit-transform: translate($x, $y, $z);
     -moz-transform: translate($x, $y, $z);
      -ms-transform: translate($x, $y, $z);
       -o-transform: translate($x, $y, $z);
          transform: translate($x, $y, $z);
}

// Backface visibility
// Prevent browsers from flickering when using CSS 3D transforms.
// Default value is `visible`, but can be changed to `hidden
// See git pull https://github.com/dannykeane/bootstrap.git backface-visibility for examples
@mixin backface-visibility($visibility){
  -webkit-backface-visibility: $visibility;
     -moz-backface-visibility: $visibility;
      -ms-backface-visibility: $visibility;
          backface-visibility: $visibility;
}

// Background clipping
// Heads up: FF 3.6 and under need "padding" instead of "padding-box"
@mixin background-clip($clip) {
  -webkit-background-clip: $clip;
     -moz-background-clip: $clip;
          background-clip: $clip;
}

// Background sizing
@mixin background-size($size){
  -webkit-background-size: $size;
     -moz-background-size: $size;
       -o-background-size: $size;
          background-size: $size;
}


// Box sizing
@mixin box-sizing($boxmodel) {
  -webkit-box-sizing: $boxmodel;
     -moz-box-sizing: $boxmodel;
      -ms-box-sizing: $boxmodel;
          box-sizing: $boxmodel;
}

// User select
// For selecting text on the page
@mixin user-select($select) {
  -webkit-user-select: $select;
     -moz-user-select: $select;
      -ms-user-select: $select;
       -o-user-select: $select;
          user-select: $select;
}

// Resize anything
@mixin resizable($direction) {
  resize: $direction; // Options: horizontal, vertical, both
  overflow: auto; // Safari fix
}

// CSS3 Content Columns
@mixin content-columns($columnCount, $columnGap: bootstrap-variables.$gridGutterWidth) {
  -webkit-column-count: $columnCount;
     -moz-column-count: $columnCount;
          column-count: $columnCount;
  -webkit-column-gap: $columnGap;
     -moz-column-gap: $columnGap;
          column-gap: $columnGap;
}

	
// Optional hyphenation
@mixin hyphens($mode: auto) {
  word-wrap: break-word;
  -webkit-hyphens: $mode;
     -moz-hyphens: $mode;
      -ms-hyphens: $mode;
       -o-hyphens: $mode;
          hyphens: $mode;
}

// Opacity
@mixin opacity($opacity: 1) {
  opacity: $opacity;
  filter: alpha(opacity=#{$opacity * 100});
}



// BACKGROUNDS
// --------------------------------------------------

// Add an alphatransparency value to any background or border color (via Elyse Holladay)
@mixin translucent-background($color: bootstrap-variables.$white, $alpha: 1) {
  background-color: hsla(color.hue($color), color.saturation($color), color.lightness($color), $alpha);
}
@mixin translucent-border($color: bootstrap-variables.$white, $alpha: 1) {
  border-color: hsla(color.hue($color), color.saturation($color), color.lightness($color), $alpha);
  @include background-clip(padding-box);
}

// Gradient Bar Colors for buttons and alerts
@mixin gradientBar($primaryColor, $secondaryColor) {
  @include gradient-vertical($primaryColor, $secondaryColor);
  border-color: $secondaryColor $secondaryColor color.adjust($secondaryColor, $lightness: -15%);
  border-color: rgba(0,0,0,.1) rgba(0,0,0,.1) fadein(rgba(0,0,0,.1), 15%);
}

// Gradients
@mixin gradient-horizontal($startColor: #555, $endColor: #333) {
  background-color: $endColor;
  background-image: linear-gradient(left, $startColor, $endColor);
  background-repeat: repeat-x;
}
@mixin gradient-vertical($startColor: #555, $endColor: #333) {
  background-color: color.mix($startColor, $endColor, 60%);
  background-image: linear-gradient(to bottom, $startColor, $endColor);
  background-repeat: repeat-x;
}
@mixin gradient-directional($startColor: #555, $endColor: #333, $deg: 45deg) {
  background-color: $endColor;
  background-repeat: repeat-x;
  background-image: linear-gradient($deg, $startColor, $endColor);
}
@mixin gradient-vertical-three-colors($startColor: #00b3ee, $midColor: #7a43b6, $colorStop: 50%, $endColor: #c3325f) {
  background-color: color.mix($midColor, $endColor, 80%);
  background-image: linear-gradient($startColor, $midColor $colorStop, $endColor);
  background-repeat: no-repeat;
}
@mixin gradient-radial($innerColor: #555, $outerColor: #333)  {
  background-color: $outerColor;
  background-image: -webkit-gradient(radial, center center, 0, center center, 460, from($innerColor), to($outerColor));
  background-image: -webkit-radial-gradient(circle, $innerColor, $outerColor);
  background-image: -moz-radial-gradient(circle, $innerColor, $outerColor);
  background-image: -ms-radial-gradient(circle, $innerColor, $outerColor);
  background-image: -o-radial-gradient(circle, $innerColor, $outerColor);
  background-repeat: no-repeat;
}
@mixin gradient-striped($color, $angle: -45deg) {
  background-color: $color;
  background-image: linear-gradient($angle, rgba(255,255,255,.15) 25%, rgba(255,255,255,0) 25%, rgba(255,255,255,0) 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, rgba(255,255,255,0) 75%, rgba(255,255,255,0));
}

// COMPONENT MIXINS
// --------------------------------------------------

// Horizontal dividers
// -------------------
// Dividers (basically an hr) within dropdowns and nav lists
@mixin nav-divider($top: #e5e5e5, $bottom: bootstrap-variables.$white) {
  height: 1px;
  margin: (math.div(bootstrap-variables.$baseLineHeight, 2) - 1) 1px; // 8px 1px
  overflow: hidden;
  background-color: $top;
  border-bottom: 1px solid $bottom;
}

// Button backgrounds
// ------------------
@mixin buttonBackground($startColor, $endColor) {
  // gradientBar will set the background to a pleasing blend of these, to support IE<=9
  @include gradientBar($startColor, $endColor);

  // in these cases the gradient won't cover the background, so we override
  &:hover, &:active, &.active, &.disabled, &[disabled] {
    background-color: $endColor;
  }
}

// Navbar vertical align
// -------------------------
// Vertically center elements in the navbar.
// Example: an element has a height of 30px, so write out `.navbarVerticalAlign(30px);` to calculate the appropriate top margin.
@mixin navbarVerticalAlign($elementHeight) {
  margin-top: (bootstrap-variables.$navbarHeight - $elementHeight) / 2;
}

// Popover arrows
// -------------------------
// For tipsies and popovers
@mixin popoverArrowTop($arrowWidth: 5px, $color: bootstrap-variables.$black) {
  bottom: 0;
  left: 50%;
  margin-left: -$arrowWidth;
  border-left: $arrowWidth solid transparent;
  border-right: $arrowWidth solid transparent;
  border-top: $arrowWidth solid $color;
}
@mixin popoverArrowLeft($arrowWidth: 5px, $color: bootstrap-variables.$black) {
  top: 50%;
  right: 0;
  margin-top: -$arrowWidth;
  border-top: $arrowWidth solid transparent;
  border-bottom: $arrowWidth solid transparent;
  border-left: $arrowWidth solid $color;
}
@mixin popoverArrowBottom($arrowWidth: 5px, $color: bootstrap-variables.$black) {
  top: 0;
  left: 50%;
  margin-left: -$arrowWidth;
  border-left: $arrowWidth solid transparent;
  border-right: $arrowWidth solid transparent;
  border-bottom: $arrowWidth solid $color;
}
@mixin popoverArrowRight($arrowWidth: 5px, $color: bootstrap-variables.$black) {
  top: 50%;
  left: 0;
  margin-top: -$arrowWidth;
  border-top: $arrowWidth solid transparent;
  border-bottom: $arrowWidth solid transparent;
  border-right: $arrowWidth solid $color;
}

// Grid System
// -----------

// Centered container element
@mixin container-fixed() {
  margin-right: auto;
  margin-left: auto;
  @include clearfix();
}

// Table columns
@mixin tableColumns($columnSpan: 1) {
  float: none; // undo default grid column styles
  width: ((bootstrap-variables.$gridColumnWidth) * $columnSpan) + (bootstrap-variables.$gridGutterWidth * ($columnSpan - 1)) - 16; // 16 is total padding on left and right of table cells
  margin-left: 0; // undo default grid column styles
}

// Make a Grid
// Use .makeRow and .makeColumn to assign semantic layouts grid system behavior
@mixin makeRow() {
  margin-left: bootstrap-variables.$gridGutterWidth * -1;
  @include clearfix();
}
@mixin makeColumn($columns: 1, $offset: 0) {
  float: left;
  margin-left: (bootstrap-variables.$gridColumnWidth * $offset) + (bootstrap-variables.$gridGutterWidth * ($offset - 1)) + (bootstrap-variables.$gridGutterWidth * 2);
  width: (bootstrap-variables.$gridColumnWidth * $columns) + (bootstrap-variables.$gridGutterWidth * ($columns - 1));
}

// The Grid
@mixin gridCore($columnWidth, $gutterWidth) {
  .row {
    margin-left: $gutterWidth * -1;
    @include clearfix();
  }

  [class*="span"] {
    float: left;
    margin-left: $gutterWidth;
  }

  // Set the container width, and override it for fixed navbars in media queries
  .container, .navbar-fixed-top .container, .navbar-fixed-bottom .container { @include gridCoreSpan(bootstrap-variables.$gridColumns, $columnWidth, $gutterWidth); }

  @include gridCoreSpanX(bootstrap-variables.$gridColumns, $columnWidth, $gutterWidth);
  @include gridCoreOffsetX(bootstrap-variables.$gridColumns, $columnWidth, $gutterWidth);
}
@mixin gridCoreSpanX($cols, $columnWidth, $gutterWidth) {
  @for $i from 1 through $cols {
    .span#{$i} { @include gridCoreSpan($i, $columnWidth, $gutterWidth) };
  }
}
@mixin gridCoreSpan($columns, $columnWidth, $gutterWidth) {
  width: ($columnWidth * $columns) + ($gutterWidth * ($columns - 1));
}
@mixin gridCoreOffsetX($cols, $columnWidth, $gutterWidth) {
  @for $i from 1 through $cols {
    .offset#{$i} { @include gridCoreOffset($i, $columnWidth, $gutterWidth); };
  }
}
@mixin gridCoreOffset($columns, $columnWidth, $gutterWidth) {
  margin-left: ($columnWidth * $columns) + ($gutterWidth * ($columns + 1));
}

@mixin gridFluid($columnWidth, $gutterWidth) {
  .row-fluid {
    width: 100%;
    @include clearfix();
    [class*="span"] {
      @include input-block-level();
      float: left;
      margin-left: $gutterWidth;
    }
    [class*="span"]:first-child {
      margin-left: 0;
    }

    // generate .spanX
    @include gridFluidSpanX(bootstrap-variables.$gridColumns, $columnWidth, $gutterWidth);
  }
}
@mixin gridFluidSpanX($cols, $columnWidth, $gutterWidth) {
  @for $i from 1 through $cols {
    .span#{$i} { @include gridFluidSpan($i, $columnWidth, $gutterWidth) };
  }
}
@mixin gridFluidSpan($columns, $columnWidth, $gutterWidth) {
  width: ($columnWidth * $columns) + ($gutterWidth * ($columns - 1));
}

@mixin gridInput($columnWidth, $gutterWidth) {
  input, textarea, .uneditable-input {
    margin-left: 0; // override margin-left from core grid system
  }

  // generate .spanX
  @include gridInputSpanX(bootstrap-variables.$gridColumns, $columnWidth, $gutterWidth);
}
@mixin gridInputSpanX($cols, $columnWidth, $gutterWidth) {
  @for $i from 1 through $cols {
    input.span#{$i}, textarea.span#{$i}, .uneditable-input.span#{$i} { @include gridInputSpan($i, $columnWidth, $gutterWidth); }
  }
}
@mixin gridInputSpan($columns, $columnWidth, $gutterWidth) {
  width: (($columnWidth) * $columns) + ($gutterWidth * ($columns - 1)) - 10;
}

@mixin makeFluidColumn($columns, $columnWidth, $gutterWidth) {
  // This isn't perfect, but it's better than nothing.
  float: left;
  margin-left: $gutterWidth;
  @include gridFluidSpan($columns, $columnWidth, $gutterWidth);
}
