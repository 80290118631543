@use "sass:list";
@use "sass:math";
/**
* Fluid grid - don't overthink it, 100% wide, fixed-height items, adaptable grid
* Does NOT need row wrappers, renders any list as a responsive table
* Don't forget to set height on items
*
* Usage (<div> is just an example, could be <ul> or any tag):
*
* <div class='fluidGrid'>
*   <div> item 1 ... </div>
*   <div> item 2 ... </div>
*   ...
* </div>
*
* Modifiers:
*
* .fluidGrid--wideCells - accomodate wider cells
* .fluidGrid--borderCollapse - same behavior as 'border-collapse: collapse' for tables
*
*/

// Media breakpoint parameters
$widths: 65536 1600 1400 1072 980 768 600 480 0;
$numColumns: 8 8 6 5 4 3 2 1;
$numColumnsWide: 6 6 4 4 3 2 2 1;

// Grid container
.fluidGrid {
  display: block;
  overflow: hidden;
  width: 100%;
}

// Grid items
.fluidGrid * {
  box-sizing: border-box;
}

.fluidGrid > * {
  display: block;
  float: left;
}

// Media queries, adapt number of columns to screen size
@for $i from 1 through 8 {
  @media (min-width: #{ list.nth($widths, $i + 1) + 1 }px) and (max-width: #{ list.nth($widths, $i) }px) {
    .fluidGrid > * {
      width: math.percentage(math.div(1, list.nth($numColumns, $i)));
    }

    .fluidGrid.fluidGrid--wideCells > * {
      width: math.percentage(math.div(1, list.nth($numColumnsWide, $i)));
    }

    .fluidGrid.fluidGrid--borderCollapse > *:not(:nth-of-type(#{ list.nth($numColumns, $i) }n + 1)) {
      border-left: 0;
    }

    .fluidGrid.fluidGrid--borderCollapse > *:nth-last-child(n + #{ list.nth($numColumns, $i) + 1 }) {
      border-bottom: 0;
    }
  }
}
