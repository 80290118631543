/* stylelint-disable selector-type-no-unknown */

@use '../core/css3';
@use 'arrange';
@use 'bootstrap';
@use 'bootstrap-changes';
@use 'bootstrap-responsive';
@use 'breadcrumbs';
@use 'button';
@use 'collapsible-section';
@use 'colors';
@use 'container';
@use 'entity-counter';
@use 'env';
@use 'errors';
@use 'feature-cell';
@use 'fluid-grid';
@use 'footer';
@use 'grid';
@use 'header';
@use 'intro-card';
@use 'jquery.sew-modifications';
@use 'main-responsive';
@use 'message';
@use 'meters/manage-data';
@use 'mixins';
@use 'modals';
@use 'multiselect';
@use 'navbar';
@use 'nprogress';
@use 'point';
@use 'point-display-status';
@use 'reset';
@use 'select2-modifications';
@use 'sidebar';
@use 'spinner';
@use 'subnav';
@use 'summary-figure';
@use 'svg-store';
@use 'table';
@use 'tags';
@use 'temporary-hacks';
@use 'toastr';
@use 'typography';
@use 'uploads-app';
@use 'upload-well';
@use 'utils-size/size';
@use 'utils/filter_dropdown';
@use 'utils/u';
@use 'checkbox';
@use 'bootstrap-variables';
@import '~@fortawesome/fontawesome-pro/css/all.css', '~pikaday/css/pikaday.css', '~select2/select2.css';

/* =============================================================
   Body, text
   ============================================================= */

body,
html {
  -webkit-appearance: none; // safety reset to avoid stupid old safari webkit "feature"
  background-color: #fff;
  color: colors.$gray900;
  font-family: 'Roboto', 'helvetica neue', Helvetica, Arial, sans-serif;
  -webkit-font-feature-settings: 'kern' 1, 'liga' 1; // ensures we use available opentype features
  font-size: 14px; // base font size
  -webkit-font-smoothing: antialiased; // subpixel-antialiasing messes up our font weights
  font-weight: 400; // base font weight
  line-height: 1.5; // Global line height of 50%. Will move to px for precision once things are no longer so messy.
  zoom: 1; // safety reset because zoom can get cray
}

a {
  color: colors.$gray900;

  &:hover {
    color: colors.$blue300;
  }
  &.underlined {
    text-decoration: underline;
  }
}

// At larger sizes, drop weight
h1,
h2,
h3 {
  font-weight: 500;
}

// At smaller sizes, keep a bolder weight
h4,
h5,
h6 {
  font-weight: 700;
}

h6 {
  color: colors.$gray600;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  color: colors.$gray700;
  font-weight: 400;
}

h1 {
  font-size: 32px;
}

h1 small {
  font-size: 14px;
}

.page-header {
  margin: 60px 23px 18px;
  padding: 0;

  @media (min-width: 1718px) {
    margin-left: 0;
  }
  > small {
    > a {
      color: colors.$gray700;
    }
  }
}


.graph-summary h1 {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -1px;
}

.graph-summary h1 medium {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -1px;
}

.graph-summary h1 small {
  color: #999;
  font-size: 14px;
}

.table-graph-summary-top {
  display: block;
}

.table-graph-summary-bottom {
  color: #999;
  display: block;
  font-size: 14px;
}

h2 {
  font-size: 28px;
}

.table-section-header {
  margin-bottom: -20px;
  padding-top: 10px;
}

h3 {
  font-size: 22px;
}

.nav-pills-spacer h3 {
  font-family: typography.$font-family;
  font-size: 20px;
}

.nav-pills-spacer h3 small {
  color: colors.$gray600;
  font-size: 14px;
}

h3 modal-title {
  margin-left: 5px;
}

.under-title-message {
  margin: 0 0 22px 21px;
}

.section-header {
  margin-bottom: 30px;
  margin-top: -10px;

  h2 {
    color: colors.$gray600;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
  }
}

h4 {
  font-weight: 18px;
}

h5,
h6 {
  font-weight: 14px;
}

.page-footer,
.page-footer a {
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: 0;
}

.title-dataservices {
  font-size: 20px;
  font-weight: 500;
  margin-top: 15px;
}

.description-small,
.description-facilities,
.description-login {
  color: colors.$gray600;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 15px;
  margin-top: 5px;
}

.description-readout {
  margin-bottom: 10px;
  margin-top: 5px;
}

.description-small {
  margin-bottom: 10px;
}

.description-facilities{
  margin-bottom: 5px;
}

.description-login {
  margin-bottom: 0;
}

.description-role {
  color: colors.$gray700;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
  margin-top: 5px;
}

.description-hr {
  border-bottom: 1px solid rgba(255, 255, 255, 0.75);
  border-top: 1px solid #ccc;
  margin-bottom: 14px;
}

.description-icon-inline {
  margin-top: 9px;
  vertical-align: top;
}

.description-integration {
  color: colors.$gray500;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.5;
}

hr {
  border-top: 1px solid colors.$gray300;
  margin-bottom: 15px;
  margin-top: 15px;
  padding: 1px 0px;
}

hr.padded {
  margin-bottom: 50px;
}

.pointer {
  cursor: pointer;
  *cursor: hand;
}

/* ==========
   glyphicons */

[class^='icon-'],
[class*=' icon-'] {
  background-image: none;
  background-position: inherit;
  background-repeat: inherit;
  display: inline-block;
  height: inherit;
  line-height: inherit;
  *margin-right: inherit;
  vertical-align: inherit;
  width: inherit;
}

[class^='icon-']:last-child,
[class*=' icon-']:last-child {
  *margin-left: inherit;
}

.fa-white {
  background-image: none;
}

/* ============
   font-awesome */

[class^='fa-'],
[class*=' fa-'] {
  vertical-align: -2px;
}

[class^='fa-'],
[class*=' fa-'] {
  display: inline-block;
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

a [class^='fa-'],
a [class*=' fa-'] {
  display: inline-block;
  text-decoration: none;
}

li [class^='fa-'],
li [class*=' fa-'] {
  display: inline-block;
  padding-right: 5px; /* added */
  text-align: center;
  vertical-align: top; /* added */
  width: 1.25em;
}

/* 'div' bumps specifity, arms race with bootstrap */
div.btn-group {
  .btn {
    background-color: bootstrap-variables.$white;
    background-image: none;
    box-shadow: none;
    padding: 11px;
  }

  .btn.active,
  .btn.active.disabled:active {
    background-color: colors.$gray200;
  }
}

.btn [class^='fa-'],
.btn [class*=' fa-'],
.nav-tabs [class^='fa-'],
.nav-tabs [class*=' fa-'] {
  padding-right: 3px;
  vertical-align: top;
}

.btn-group [class^='fa-'],
.btn-group [class*=' fa-'] {
  vertical-align: middle;
}

.btn-small-table [class^='fa-'],
.btn-small-table [class*=' fa-'] { /* added */
  margin-left: -2px;
  padding-right: 0;
}

.label-tooltip .fa-question-circle {
  width: 14px;
}

/* mark as superuser feature */
.fa-su {
  vertical-align: top !important;

  &:after {
    content: '\f023';
    font-size: 20px;
    margin-left: -20px;
    opacity: 0.2;
    position: absolute;

  }

  &.fa-button:after {
    margin-left: 2px;
  }
}

.fa-superpartner {
  vertical-align: top !important;
  height: 20px;
  width: 20px;

  &:after {
    content: url('/static/www/icons/lock-super-partner.svg');
    font-size: 20px;
    margin-left: -20px;
    opacity: 0.2;
    position: absolute;
  }

  &.fa-button:after {
    margin-top: -5px;
    margin-left: 2px;
  }
}

.fa-partner {
  vertical-align: top !important;
  height: 20px;
  width: 20px;

  &:after {
    content: url('/static/www/icons/lock-partner.svg');
    font-size: 20px;
    margin-top: -20px;
    opacity: 0.2;
    position: absolute;

  }

  &.fa-button:after {
    margin-left: 2px;
  }
}

.fa-sp:after {
  opacity: 0.4;
  color: blue;
}

dt > .fa-su:after {
 margin-left: 0px;
 margin-top: -5px;
}

h1 small .fa-su:after {
  margin-left: -7px;
  margin-top: 15px;
}

dt > .fa-partner:after {
  margin-left: 0px;
  margin-top: -5px;
 }
 
h1 small .fa-partner:after {
  margin-left: -7px;
  margin-top: 15px;
}

dt > .fa-superpartner:after {
  margin-left: 0px;
  margin-top: -5px;
 }
 
h1 small .fa-superpartner:after {
  margin-left: -7px;
  margin-top: 15px;
}

li .fa-lg:before,
li .fa-lg:before {
  /* 1.5 increased font size for fa-lg * 1.25 width */
  width: 1.875em;
}

ul.icons {
  list-style-type: none;
  margin-left: 2em;
  text-indent: -0.8em;

  li [class^='fa-'],
  li [class*=' fa-'] {
    width: 0.8em;
  }

  li .fa-lg:before,
  li .fa-lg:before {
    vertical-align: initial; /* 1.5 increased font size for fa-lg * 1.25 width */
  }
}

/* new for table icon alignment */
td [class^='fa-'],
td [class*=' fa-'] {
  padding-right: 0;
  vertical-align: top;
  width: 1.25em;
}

/* new for alert box alignment */
.alert [class^='fa-'],
.alert [class*=' fa-'] {
  padding-right: 3px;
  vertical-align: top;
  width: 1.25em;
}

/* new for action bar alignment */
.action-bar [class^='fa-'],
.action-bar [class*=' fa-'] {
  padding-bottom: 2px;
  vertical-align: middle;
  width: 1.25em;
}

/* new for icon alignment within regular text */
p [class^='fa-'],
p [class*=' fa-'] {
  padding-right: 3px;
  vertical-align: top;
  width: 1.25em;
}

/* new for icon alignment within regular text */
span [class^='fa-'],
span [class*=' fa-'] {
  padding-right: 0;
  vertical-align: top;
  width: 1.25em;
}

.status [class^='fa-'],
.status [class*=' fa-'] {
  line-height: 1.25em;
  padding-right: 5px;
  vertical-align: top;
}

/* makes the font 33% larger relative to the icon container */
.btn .fa-lg,
.fa-lg {
  font-size: 4/3em;
  vertical-align: middle;
}

a.btn .lone-icon {
  margin-left: 0;
}

.fa-filter:before {
  display: none;
}

td:hover .fa-filter:before {
  display: inline-block;
}

/* =============================================================
   Forms
   ============================================================= */

label,
input,
button,
select,
textarea {
  font-size: 14px;
}

label {
  font-weight: 700;
}

textarea,
input[type='text'],
input[type='password'],
input[type='datetime'],
input[type='datetime-local'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='search'],
input[type='tel'],
input[type='color'],
.uneditable-input {
  font-size: 14px;
}

textarea:focus,
input[type='text']:focus,
input[type='password']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='date']:focus,
input[type='month']:focus,
input[type='time']:focus,
input[type='week']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='color']:focus,
.uneditable-input:focus {
  border: 1px solid rgba(18, 107, 150, 1);
  box-shadow: 0 0 3px rgba(18, 107, 150, 0.5);
}

input[type='color'] {
  height: 28px !important;
  padding: 2px 4px !important;
  width: 28px !important;
}

textarea {
  min-height: 60px;
  padding: 8px;
}

textarea.form-control {
  resize: vertical;
}

select,
input[type='file'] {
  height: 34px;
  line-height: 34px;
}

select:focus,
input[type='file']:focus,
input[type='radio']:focus,
input[type='checkbox']:focus {
  outline: 4px auto -webkit-focus-ring-color;
}

.row-fluid .input-append [class*='span'],
.row-fluid .input-prepend [class*='span'] {
  min-height: 36px;
}

select.adjacent {
  display: block;
  width: 100px;
}

.radio,
.checkbox {
  padding-top: 3px;
}

.checkbox-group,
.radio-group {
  line-height: 16px;
  margin-top: 6px;
}

.radio.inline,
.checkbox.inline {
  padding-top: 12px;
}

.radio.inline.units-label,
.checkbox.inline.units-label {
  margin-left: 6px;
  margin-right: 6px;
  vertical-align: top;
}

.radio-metric {
  margin-top: -20px;
}

.dropdown-group {
  margin-bottom: 9px;
}

.checkbox-group {
  margin-bottom: 9px;
}

.checkbox-group.trailing {
  margin-top: -15px;
}

.btn-group.multiselect,
.btn-group.toggleselect {
  display: inline;
}

.control-group.toggleselect-padding {
  padding-bottom: 9px;
}

.control-group.stepwise {
  margin-left: -159px;
}

.control-plaintext {
  color: colors.$gray900;
  margin-left: 151px;
  padding: 10px;
}

.form-actions {
  border-top: none;
}

:-moz-placeholder {
  color: colors.$gray500;
}

:-ms-input-placeholder {
  color: colors.$gray500;
}

::-webkit-input-placeholder {
  color: colors.$gray500;
}

.help-block,
.help-inline {
  color: colors.$gray600;
  margin-top: 9px;
  vertical-align: top;
}

.help-block.dropdown {
  padding-top: 10px;
}

.help-block-subtext a {
  text-decoration: underline;
}

.form-horizontal .help-block {
  clear: left;
  margin-bottom: 5px;
  margin-top: 0;
}

.form-horizontal .steps-form {
  margin-left: 0;
  padding-top: 10px;
}

.form-horizontal.form-graph {
  margin-top: 40px;
}

.form-horizontal .form-horizontal-cancel .control-label {
  float: none;
  font-weight: normal;
  text-align: left;
}

.form-horizontal .form-horizontal-cancel .controls {
  margin-left: 0;
}

.EditableElement {
  border: 1px solid transparent;
  border-radius: 5px;
  margin-bottom: 5px;
  position: relative;

  &.hover {
    border: 1px solid colors.$gray400;
    box-shadow: inset 0 0 2px 0 rgba(17, 25, 31, 0.12), inset 0 2px 2px 0 rgba(17, 25, 31, 0.24);
  }
}

.navbar-breadcrumbs .EditableElement {
  pointer-events: none;

  &:after {
    background-image: none;
  }
}

.EditableForm {
  display: inline-block;
  margin: 0;

  input[type] {
    border: 1px solid colors.$blue300;
    border-radius: 5px;
    box-shadow: inset 0 0 2px 0 rgba(17, 25, 31, 0.12), inset 0 2px 2px 0 rgba(17, 25, 31, 0.24);
    margin: 0;
    padding: 0;
  }
}

.form-field-subtext {
  padding: 0 161px 16px;

  a {
    text-decoration: underline;
  }
}

.steps {
  margin-bottom: 40px;
  margin-left: 20px;

  &.steps--initial {
    margin-bottom: 0;
  }
}

.steps-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  margin-left: 65px;
  padding-top: 16px;
}

.steps-text {
  margin-left: 65px;
  padding-top: 20px;
}

.control-group.error .input-append .add-on.selected,
.input-prepend .active,
.input-append .active {
  background-color: colors.$green300;
  border-color: colors.$green400;
}

.control-group.error .input-append .add-on {
  background-color: #eee;
}

.caret {
  filter: alpha(opacity=100);
  opacity: 1;
}

.caret-dropdown-menu { /* substitution for select2 */
  font-size: 11px;
  margin-top: 10px;
}

.search-dropdown-menu { /* substitution for select2 */
  color: #999;
  font-size: 16px;
  margin-left: -20px;
}

.form-horizontal .control-label {
  padding-top: 10px;
}

.form-horizontal .controls-text {
  padding-top: 8px;
}

.dropdown-menu {
  background-color: #fff;
  box-shadow: 0 10px 16px -2px rgba(0, 0, 0, 0.5);
  min-width: 230px;

  a {
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 7px 33px 7px 23px;
    white-space: nowrap;

    .svg-symbol{
      vertical-align: middle;
      margin-top: -3px;
    }
  }
}
.dropdown-menu li > a:hover,
.dropdown-menu .active > a,
.dropdown-menu .active > a:hover {
  background-color: colors.$gray200;
  color: colors.$blue300;
}

.dropdown-menu.notifications {
  max-width: 365px;
}

.dropdown-menu .divider {
  margin: 4px 1px;
}

div.btn-group .dropdown-menu {
  .btn-link,
  a {
    color: #222;
    font-size: 13px;
    padding: 6px 15px;

    i[class^='fa-'] {
      padding-right: 3px;
    }
  }

  .btn-link {
    border-width: 0;
    display: block;
    text-align: left;
    white-space: nowrap;
  }

  button.btn-link {
    font-weight: normal;
    width: 100%;
  }
}

div.btn-group .dropdown-menu {
  li > a:hover,
  li > form > a:hover,
  li > form > .btn-link:hover,
  .active > a,
  .active > a:hover {
    background-color: #eee;
    color: #222;
    padding-top: 6px;
  }
}

.errorlist {
  margin: 0;
  padding: 0;
}

.control-group.error .add-on {
  clear: left;
  color: #b94a48;
}

.FieldLabelOptional {
  color: bootstrap-variables.$gray;
  font-weight: normal;
}

#reportSaveModalForm {
  .FieldLabelOptional {
    display: none;
  }

  .FormRow {
    &.is-checkbox.irregular-checkbox {
      margin-top: -9px;

      .controls label {
        font-weight: normal;

        input {
          margin-right: 5px;
          vertical-align: baseline;
        }
      }
    }

    .regular-radio {
      li label {
        font-weight: normal;

        input {
          margin-right: 5px;
        }
      }
    }
  }
  .controls {
    .multiselect {
      label {
        font-weight: initial;

        input[type='checkbox'] {
          vertical-align: bottom;
        }
      }
    }
  }
}

/* ==============
   append/prepend */

.input-prepend input,
.input-append input,
.input-prepend select,
.input-append select,
.input-prepend .uneditable-input,
.input-append .uneditable-input {
  vertical-align: top;
}

.input-prepend .add-on,
.input-append .add-on {
  height: 24px;
  line-height: 24px;
  padding: 4px 5px 4px;
}

.input-append .add-on.selected {
  background-color: colors.$green300;
  border-color: colors.$green400;
}

.FormRow {
  .input-prepend input,
  .input-prepend select,
  .input-prepend uneditable-input {
    border-radius: 0 5px 5px 0;
    margin-left: -1px;
  }

  .input-append input,
  .input-append select,
  .input-append uneditable-input {
    border-radius: 5px 0 0 5px;
  }
}

/* ============
   non-standard */

.description-prepend { /* using dropdowns */
  display: inline;
  font-size: 24px;
  font-weight: 500;
  margin-right: 5px;
}

.description-prepend icon {
  margin-right: 4px;
}

.description-prepend.toggleselect { /* using toggle buttons */
  margin-right: 12px;
  margin-top: 7px;
}

.time-dropdown,
.time-dropdown-adjacent {
  margin-right: 15px;
  width: 80px;
}

.add-button-adjacent {
  margin-right: 15px;
}

/* =============================================================
   Buttons
   ============================================================= */

.btn {
  font-size: 13px;
  font-weight: 500;
  padding: 8px 10px 8px;
}

.btn-large {
  font-size: 24px;
  padding: 19px 24px;
}

.btn-small {
  font-size: 12px;
}

.btn-small-table {
  font-size: 12px;
  font-weight: normal;
  padding: 4px 7px 3px;
}

.btn-mini {
  font-size: 12px;
}

.btn-mini.inheader {
  font-size: 12px;
  line-height: 14px;
  margin-left: 8px;
  margin-top: -5px;
  padding: 4px 9px;
}

.btn-action-table {
  min-width: 150px;
}
// TODO: remove when we're up to 2.3.2
// Make a button look and behave like a link
.btn-link,
.btn-link:active,
.btn-link[disabled] {
  background-color: transparent;
  background-image: none;
  box-shadow: none;
}

.btn-link {
  border-color: transparent;
  border-radius: 0;
  cursor: pointer;
}

.btn-link:hover,
.btn-link:focus {
  background-color: transparent;
  text-decoration: underline;
}

.btn-link[disabled]:hover,
.btn-link[disabled]:focus {
  text-decoration: none;
}

.fa-large-button {
  font-size: 17px;
  line-height: 0.9em;
  margin-top: 1px;
}

.fa-extralarge {
  color: #999;
  font-size: 50px;
  line-height: 50px;
}

.fa-header-subtitle {
  color: colors.$gray700;
  font-size: 14px;
  margin-right: -5px;
  margin-top: 22px;
  padding-left: 4px;
}

.fa-facebook.footer {
  margin-right: -6px;
}

.fa-google-plus.footer {
  margin-left: -1px;
  margin-right: -8px;
}

.btn-edit-inline a {
  color: colors.$gray700;
  font-size: 19px;
  text-decoration: none;
}

.btn-group > .btn.active,
.btn-group > .btn:active {
  background-color: #ccc;
}

.btn-group > .btn.disabled:active {
  background-color: inherit;
}

.btn-group > .btn[disabled]:active {
  background-color: #e6e6e6;
}

#points-actions.action-button--disabled {
  opacity: 50%;
  pointer-events: none;
}

#graph-button.graph-button--disabled {
  opacity: 50%;
  pointer-events: none;
}

.buttons-radio > .btn input[type='radio'],
[data-toggle='buttons-radio'] > .btn input[type='radio'],
{
  display: none;
}

.btn-primary {
  background-color: #2a2a2a;
  background-image: linear-gradient(to bottom, #2f2f2f, #222);
  background-repeat: repeat-x;
  border-color: #222 #222 #000;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary.active,
.btn-primary.disabled,
.btn-primary[disabled] {
  background-color: #222;
}

.btn-primary:active,
.btn-primary.active {
  background-color: #080808 \9;
}

.btn-success.large-green-button {
  color: #fff;
  font-family: typography.$font-family;
  font-size: 14px;
  font-weight: 500;
  text-shadow: none;
}

.btn-danger.large-red-button {
  color: #fff;
  font-family: typography.$font-family;
  font-size: 14px;
  font-weight: 500;
  text-shadow: none;
}

input[type='submit'],
button {
  background: #8dc63f;
  border: none;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  font-family: typography.$font-family;
  font-weight: 400;
  height: auto;
  overflow: visible;
  padding: 7px 20px;
  text-decoration: none;
  transition: background-color 0.2s ease;
  width: auto;
}

input[type='submit']:hover {
  background: #7db800;
  color: #fff;
}

.btn-block {
  box-sizing: border-box;
  display: block;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 5px;
}

input[type='submit'].btn-block,
input[type='reset'].btn-block,
input[type='button'].btn-block {
  width: 100%;
}

.close,
.notify-close {
  color: #000;
  filter: alpha(opacity=20);
  float: right;
  font-size: 20px;
  font-weight: bold;
  line-height: 18px;
  opacity: 0.2;
  text-shadow: 0 1px 0 #fff;
}

.close:hover,
.notify-close:hover {
  color: #000;
  cursor: pointer;
  filter: alpha(opacity=40);
  opacity: 0.4;
  text-decoration: none;
}

button.close,
button.notify-close {
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
}

.notify-close {
  margin-left: 15px;
  margin-right: 5px;
  margin-top: -10px;
}

/* =============================================================
   Tables
   ============================================================= */

table {
  table-layout: fixed;
}

.table-striped tbody tr:nth-child(odd) td,
.table-striped tbody tr:nth-child(odd) th {
  background-color: #f0f5f7;
}

.table-striped tbody tr:nth-child(even):hover {
  background-color: #fff;
}

.table-striped tbody tr:nth-child(even) td:hover,
.table-striped tbody tr:nth-child(even) th:hover {
  background-color: #fff;
}

.table-striped {
  thead th:first-child,
  tbody td:first-child {
    padding-left: 15px;
  }
}

// This is ugly, but all this table-striped nonsense needs to be thrown out soon
// anyways. Easier to override to stop the row hover madness for, and then
// revisit when we really tear apart table styles.
tr.even:hover {
  td {
    background-color: #fff !important;
  }
}

td.name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.systemName {
  @include mixins.break-all;

  word-wrap: break-word;
}

td.facility-name {
  font-size: 18px;
}

td.connected-meters {
  font-size: 24px;
  letter-spacing: -2px;
}

.avatar-table {
  border-bottom: 1px solid #fff;
}

.avatar-table-inline {
  border-bottom: 1px solid #fff;
  margin-left: 10px;
  margin-right: 10px;
}

// Hacky fix. Remove when table styles aren't all over the place
.table > thead > tr > th {
  padding: 0;
  padding-left: 8px;

  > span {
    display: inline-block;
  }
}

.table th,
.table td {
  border-bottom: 0 solid #ddd;
  vertical-align: middle;
}

.table th {
  font-weight: 500;
  line-height: 1.5;
}

th a,
th a:hover {
  display: block;
  text-decoration: none;
}

.table td {
  background-color: #fff;
  border: none;
}

.table-striped tbody tr:nth-child(1n+1),
.graph-summary tbody tr:nth-child(1n+1) {
  // border-left: 1px solid #ddd;
  // border-right: 1px solid #ddd;
}

table form,
.nav form {
  margin: 0;
}

td.edit-button {
  padding-left: 20px;
}

td.online {
  background-color: #dff0d8;
  color: #468847;
  padding-left: 20px;
}

td.offline {
  background-color: #f2dede;
  color: #b94a48;
  padding-left: 20px;
}

td.not-connected {
  background-color: #f5f5f5;
  color: #ccc;
  padding-left: 20px;
}

.table-search {
  float: right;
}

.table-search label {
  display: inline;
  margin-right: 5px;
}

/* =======================
   graph table summaries */

.table-graph-summary th,
.table-graph-summary td {
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  padding: 30px 20px 20px 20px;
  text-align: left;
  vertical-align: top;
}

.table-graph-summary th {
  background-color: none;
  font-size: 18px;
  font-weight: bold;
}

/* =================================
   portfolio graph table summaries */

.table-portfolio-summary th,
.table-portfolio-summary td {
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  padding: 30px 30px 20px 20px;
  text-align: left;
  vertical-align: top;
}

.table-portfolio-summary th {
  background-color: none;
  font-size: 18px;
  font-weight: bold;
}

/* ======
   labels */

.no-label label {
  display: none;
}

.pagination {
  display: block;
  margin-bottom: 45px;
  margin-top: 30px;

  // reset <ul>
  ul {
    margin: 0;
    padding: 0;
  }

  a {
    color: colors.$blue500;
    display: block;
    font-size: 15px;
    text-decoration: none;
  }
}

.pagination-prev,
.pagination-next {
  font-weight: 500;
  line-height: 1.5;
  min-width: 100px;

  i {
    margin-top: 1px; // hack because font-awesome line-height is inconsistent
    padding-right: 0; // hack around our global padding reset
  }

  a {
    &:hover {
      color: colors.$blue400;
    }
  }
}

.pagination-next {
  text-align: right;
}

.pagination-number {
  display: inline-block;

  a {
    border-radius: 2px;
    font-size: 15px;
    line-height: 1.5;
    padding: 2px 10px;

    &:hover {
      background-color: colors.$blue300;
      color: white;
    }
  }
}

.pagination-number--active {
  background-color: colors.$blue500;
  border-radius: 2px;
  color: white;
  font-size: 15px;
  line-height: 1.5;
  padding: 2px 10px;
}

.pagination-number--disabled {

}

/* =============================================================
   Nav lists, tabs, pills
   ============================================================= */


.nav > li > a:hover {
  background-color: #fff;
}

.nav a,
.navbar .brand,
a.btn,
.dropdown-menu .btn-link,
.dropdown-menu a {
  text-decoration: none;
}

/* ================================
   .nav-list, .nav-tabs, .nav-pills */

.nav-list > .active > a,
.nav-list > .active > a:hover {
  background-color: #222;
}

.nav-tabs > li > a,
.nav-pills > li > a {
  color: #000;
  line-height: 14px;
  margin-right: 6px;
  padding-left: 16px;
  padding-right: 16px;
}

.nav-tabs > .active > a,
.nav-tabs > .active > a:hover {
  color: #888;
}

.nav-pills > li > a {
  padding-bottom: 10px;
  padding-top: 10px;
  transition-duration: 0.2s;
}

.nav-pills > .active > a,
.nav-pills > .active > a:hover {
  background-color: colors.$gray700;
  text-shadow: none;
}

.nav-pills a:active {
  position: relative;
  top: 2px;
}

.nav-tabs .dropdown-toggle .caret,
.nav-pills .dropdown-toggle .caret {
  border-bottom-color: #222;
  border-top-color: #222;
}

.nav-tabs .dropdown-toggle:hover .caret,
.nav-pills .dropdown-toggle:hover .caret {
  border-bottom-color: #000;
  border-top-color: #000;
}

.dropdown-toggle.dropdown-toggle-menu a,
.dropdown-toggle.dropdown-toggle-menu a:hover {
  text-shadow: none;
}

.dropdown-toggle {
  white-space: nowrap;
}

.inset-selected { /* future hook */
}

/* =========================
   .nav-pills position, text */

.nav-pills.centered > li { /* rename? */
  display: inline-block;
  *display: inline;
  float: none;
  zoom: 1;
}

.nav-pills.centered { /* rename? */
  text-align: left;
}

.nav-pills-spacer {
  margin-left: 3px;
  margin-right: 20px;
  margin-top: 7px;
}

.pills-text {
  font-size: 21px;
  margin-left: 2px;
  margin-right: 8px;
}

/* ============
   .bottom-pills */

.bottom-pills > li > a {
  margin-right: 0;
  padding-top: 11px;
}

/* ============
   .graph-pills */

.graph-pills {
  line-height: 44px;
}

.nav-pills.graph-pills > li > a {
  background-color: #fff;
  box-shadow: 0 8px 6px -8px rgba(0, 0, 0, 0.2);
  margin-bottom: 2px;
  margin-top: 2px;
  padding-bottom: 15px;
  padding-top: 15px;
  transition-duration: 0s;
}

.graph-pills a:active { /* overwrite nav-pills effect */
  position: inherit;
  top: 0;
}

.nav-pills.graph-pills > li > a:hover {
  background-color: colors.$gray700;
  color: #fff;
  text-shadow: none;
}

.nav-pills.graph-pills .dropdown-toggle:hover .caret {
  border-bottom-color: #fff;
  border-top-color: #fff;
}

.dropdown-toggle.dropdown-toggle-graph {
  font-size: 20px;
  text-shadow: none;
}

.graph-pills-text {
  font-size: 14px;
  font-weight: 300;
  margin-left: 2px;
  margin-right: 8px;
  vertical-align: top;
}

/* ============
   .group-pills */

.group-pills {
  font-size: 26px;
  line-height: 36px;
  margin-bottom: 30px;
  margin-top: -20px;
  text-align: center;
}

.group-pills-arrows {
  margin-left: 11px;
  margin-right: 8px;
}

.group-pills-text {
  font-size: 21px;
  margin-left: 9px;
  margin-right: 8px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.75);
  vertical-align: middle;
}

.action-bar {
  background-color: colors.$yellow100;
  border: 2px solid colors.$yellow200;
  border-radius: 3px;
  font-weight: 500;
  line-height: 28px;
  padding: 20px 15px 15px 15px;
  text-align: center;
}

.action-bar.link-main a {
  color: colors.$gray700;
  font-size: 14px;
  padding-left: 6px;
}

.action-bar a:hover {
  color: colors.$gray700;
  text-decoration: none;
}

.notify-bar {
  line-height: 15px;
  padding-top: 25px;
  text-align: left;

  /* margin-bottom: 0px; fixes push down of avatar-user */
}

.notify-bar-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 11px;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 7px;
}

.notify-bar-item timestamp {
  color: colors.$gray600;
  font-weight: 400;
  margin-left: 2px;
}

.notify-bar-item-more {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.notify-bar-item-more,
.notify-bar-item-more a {
  color: colors.$gray700;
  font-size: 12px;
}

.notify-bar-item-more a:hover {
  color: colors.$gray700;
  text-decoration: none;
}

/* =============================================================
   Boxes, wells
   ============================================================= */

.alert-neutral {
  background-color: #f5f5f5;
  border-color: #ddd;
  color: #ccc;
}

.alert-note,
.alert-note-inheader {
  background-color: #fcf8e3;
  background-image: linear-gradient(to bottom, rgb(252, 248, 227) 0%, rgb(252, 247, 202) 100%);
  border: 1px solid #fbeed5;
  border-radius: 2px;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.2);
  color: #333;
  font-size: 22px;
  line-height: 28px;
  margin-top: 20px;
  max-width: 220px;
  padding: 15px 17px 18px 17px;
}

.alert-note-logo {
  border: 1px solid #eee;
  border-radius: 2px;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
}

.alert-note-text {
  color: #999;
  font-family: typography.$font-family;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 15px;
  margin-top: 10px;
}

.alert-note-inheader {
  margin-left: -19px;
  margin-top: -58px;
}

.alert-icon {
  font-size: 36px;
  margin-bottom: 7px;
  margin-top: 7px;
  text-align: center;
}

.alert-notify {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3));
  border-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0) rgba(255, 255, 255, 0.75);
  border-radius: 5px;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.5);
  color: #fff;
  padding-top: 20px;
}

.notify-title { /* removed? */
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 3px;
}

.notify-item,
.notify-item-more {
  color: #fff;
  font-size: 14px;
  margin-bottom: 8px;
  margin-left: 10px;
}

.notify-item-more,
.notify-item-more a {
  color: #fff;
  font-size: 12px;
}

.notify-item-more a:hover {
  color: #ffc;
  text-decoration: none;
}

.save-item {
  color: #fff;
  font-size: 14px;
  margin-left: 12px;
}

.label-notify,
.badge-notify {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3));
  border-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0) rgba(255, 255, 255, 0.75);
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.label-notify[href],
.badge-notify[href] {
  background-color: colors.$gray700;
}

code,
pre,
pre.prettyprint,
.well {
  background-color: #eee;
}

.hero-unit {
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.table-bordered,
.well,
.prettyprint {
  border-radius: 0;
}

.disabled-box {
  background-color: #555;
  height: 100%;
  left: 0;
  margin: 0;
  opacity: 0.4;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.cloud-app .disabled-box {
  border-radius: 0 0 5px 5px;
}

/* ========
   zero box */

.zero-box {
  background-color: colors.$yellow100;
  border: 2px solid colors.$yellow200;
  border-radius: 3px;
  padding: 20px 15px 38px 15px;
  text-align: center;

  a {
    text-decoration: underline;
  }

  .large {
    font-size: 20px;
  }
}

/* ========
   callout box */

.callout-box {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 2px 0 rgba(0.1, 0.15, 0.2, 0.12), 0 2px 2px 0 rgba(0.1, 0.15, 0.2, 0.24);

  /* border: 1px solid $gray300; */
  padding: 10px;
  padding-top: 15px;

  img {
    float: left;
    margin-left: 10px;
    margin-right: 20px;
  }

  p {
    margin-left: 55px;
  }

  a {
    text-decoration: underline;
  }
}

/* timestamps */

.localtime {
  display: none;
}

/* ===========
   grid labels */

.status {
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 9px 2px;
  vertical-align: middle;

  i {
    display: inline !important;
  }
}

.status-online,
.status-offline {
  color: black;
}

table .status {
  display: inline-block;
}

.status-notconnected {
  background-color: #f5f5f5;
}

.status-notconnected {
  border: 1px solid #ddd;
  border-radius: 4px 4px 4px 4px;
  color: #ccc;
  padding: 2px 9px 3px;
}

.control-status {
  padding-top: 10px;
}

/* =============================================================
   Graphs
   ============================================================= */

.graph-bounds {
  line-height: 18px;
}

/* =============================================================
   Itembar, cloud
   ============================================================= */

.itembar {
  margin-bottom: 18px;
  margin-left: auto;
  margin-right: auto;
  overflow: visible;
  *position: relative;
  vertical-align: top;
  *z-index: 2;
}

.container .itembar .container {
  width: auto;
}

.itembar-inner {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 8px 2px -6px rgba(0, 0, 0, 0.2);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px; /* extra padding above main page title */
}

.cloud-apps.row {
  margin-left: -15px;
}

.cloud,
.cloud-service,
.cloud-app {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 1px 0 #e4ebef;
  display: inline-block;
  float: none;
  margin-bottom: 30px;
  margin-left: 15px;
  transition: box-shadow ease-out 0.2s;
  vertical-align: top;
}

.cloud a,
.cloud-app a {
  text-decoration: none;
}

// TODO: Remove when integrations page gets redesigned
.cloud-service a {
  text-decoration: none;
}

.cloud:hover,
.cloud-service:hover,
.cloud-app:hover {
  background: #f2f2ec;
  color: #222;
  text-decoration: none;
  transition: box-shadow 0s;
}

.cloud-service {
  background-color: #f2f2ec;
}

// Because we set the .cloud-app class directly on the span3 <div>, we cannot
// add a border to that element directly, because the grid breaks and wraps.
// This is a temporary hack that will be removed ASAP once we tackle the tiles.
.inside-cloud {
  border: 1px solid colors.$gray400;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top: none;
  padding: 20px 20px 20px 20px;
}

.hastooltip + div {
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  display: none;
  margin-top: -34px;
  padding: 8px 12px 5px 15px;
  position: absolute;
}

.hastooltip:hover + div,
.hastooltip + div:hover {
  display: block;
}

@media (min-width: 1200px) {
  .cloud-apps.row {
    margin-left: -25px;
  }

  .cloud,
  .cloud-service,
  .cloud-app {
    margin-left: 25px;
  }
}

/* =============================================================
   Images
   ============================================================= */

img.app,
img.facility,
img.service {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}

img.profile,
img.graph-summary {
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.2);
}

img.graph-summary {
  margin-top: 41px;
}

img.fade {
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
}

img.fade:hover {
  opacity: 0.5;
}

img.energystar {
  width: 306px;
  height: 99px;
  margin-bottom: 16px;
}

.title-avatar {
  border-radius: 16px;
  margin-right: 8px;
  width: 80px;
}

.polaroid-photo-user {
  max-width: 100px;
  text-align: center;
}

.photo-user-label {
  color: colors.$gray800;
  font-size: 18px;
  line-height: 1.5;
  margin-top: 7px;
}

.popover-image {
  margin-bottom: 10px;
}

.illustration {
  text-align: center;
}

.illustration-logo {
  border-radius: 2px;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
}

.illustration-none {
  margin-bottom: 50px;
  text-align: center;
}

.illustration-none.table {
  margin-bottom: 59px;
}

.avatar-user {
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 5px;
  margin-right: 20px;
  margin-top: 0;
}

.avatar-adjust { /* fixes push up of avatar-user (temporary) */
  margin-top: 15px;
}

/* =============================================================
   Popovers, popups, modals
   ============================================================= */

.popover-title {
  border-bottom: 0 solid #eee;
  padding: 0 0;
}

.popover-inner {
  max-width: 280px;
  width: auto;
}

.popover-content {
  border-radius: 3px 3px 3px 3px;
}

.popover-content p {
  margin-bottom: 1em;
}

/* push the popover down and widen so all the contents are easily visible */
.build-info {
  top: 0 !important;
}
.build-info .popover-inner {
  max-width: none;
}

.textarea-modal {
  height: 200px;
}

.textarea-medium {
  height: 100px;
}

.textarea-small {
  height: 50px;
}

.textarea-padding {
  margin-bottom: 9px;
}

.theme-thumb-left {
}

.theme-thumb-right {
  margin-right: 10px;
}

.label-thumb {
  margin-bottom: 30px;
}

.row-thumb {
  margin-left: 0;
}

/* =============================================================
   Accordions
   ============================================================= */

.accordion-group {
  background-color: #fff;
  margin-bottom: 6px;
}

.accordion-heading {
  font-size: 16px;
}

.accordion-heading .accordion-toggle {
  padding: 12px 15px;
}

/* =============================================================
   Shapes
   ============================================================= */

.spot {
  background: #71be42;
  background-image: linear-gradient(to bottom, rgb(188, 255, 146) 0%, rgb(144, 231, 91) 5%, rgb(124, 202, 79) 100%);
  border: 1px solid #ddd;
  border-color: #7dca4f #71be42 #63a73a;
  border-radius: 64px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  color: #fff;
  font-family: typography.$font-family;
  font-size: 15px;
  font-weight: 700;
  height: 32px;
  line-height: 32px; /* 16px for two lines of text */
  margin-left: -16px;
  margin-top: -20px;
  padding: 16px 0;
  position: absolute;
  text-align: center;
  text-shadow: none;
  text-shadow: #63a73a 0 -1px 0;
  width: 64px;
}

.circle-small {
  background: #71be42;
  background-image: linear-gradient(to bottom, rgb(188, 255, 146) 0%, rgb(144, 231, 91) 5%, rgb(124, 202, 79) 100%);
  border: 1px solid #ddd;
  border-color: #7dca4f #71be42 #63a73a;
  border-radius: 64px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  color: #fff;
  font-family: typography.$font-family;
  font-size: 30px;
  font-weight: 700;
  height: 14px;
  line-height: 16px;
  margin-top: 5px;
  padding: 14px 0;
  position: absolute;
  text-align: center;
  text-shadow: none;
  text-shadow: #63a73a 0 -1px 0;
  width: 45px;
}

/* =============================================================
   Sizes, colors
   ============================================================= */

.small {
  font-size: 11px;
}

.medium {
  font-size: 16px;
}

.large {
  font-size: 18px;
  line-height: 1.5;
  padding: 22px 40px;
}

.green {
  color: #468847;
}

.blue {
  color: #08c;
}

.purple {
  color: #c24b87;
}

.red {
  color: #b94a48;
}

.orange {
  color: #f89406;
}

.yellow {
  color: #c09853;
}

.gray {
  color: #888;
}

.white {
  color: #fff;
}

/* =============================================================
   Data tables
   ============================================================= */

div.dataTables_length label {
  float: left;
  text-align: left;
}

div.dataTables_length select {
  height: 38px;
  line-height: 38px;
  width: 75px;
}

div.dataTables_filter label {
  float: right;
}

div.dataTables_info {
  padding-left: 10px;
  padding-top: 8px;
}

div.dataTables_paginate {
  float: right;
  margin: 0;
  width: 100%;

  ul {
    float: right;

    li {
      float: left;
      margin: 0 3px 0 0;

      a {
        border-radius: 2px;
        color: colors.$blue500;
        display: inline-block;
        padding: 5px 10px;
      }
    }

    li.active a {
      background-color: colors.$blue500;
      color: white;
    }

    li.disabled a {
      display: none;
    }

    li:last-child {
      margin: 0;
    }

    li:hover a {
      background-color: colors.$blue400;
      color: white;
    }
  }
}

table.table {
  clear: both;
  margin-bottom: 15px;
}

table.table thead .sorting,
table.table thead .sorting_asc,
table.table thead .sorting_desc,
table.table thead .sorting_asc_disabled,
table.table thead .sorting_desc_disabled {
  cursor: pointer;
  *cursor: hand;
}

table.dataTable th:active {
  outline: none;
}

table.dataTable > thead > tr > th > a:hover {
  background: colors.$gray400;
  color: colors.$gray700;
}

table.dataTable th.no-hover:hover {
  background: none;
}

table th.right-button .btn-group,
table td.right-button .btn-group {
  float: right;
}

table td.right-button .btn-group a {
  white-space: nowrap;
}

tr.access-granted {
  color: #468847;
  font-weight: 700;
}

/* ===========
   table tools */

.table tbody tr {
  height: 44px;
}

.table tbody tr.active td,
.table tbody tr.active th {
  background-color: #08c;
  color: white;
}

.table tbody tr.active:hover td,
.table tbody tr.active:hover th {
  background-color: #0075b0 !important;
}

.table-striped tbody tr.active:nth-child(odd) td,
.table-striped tbody tr.active:nth-child(odd) th {
  background-color: #017ebc;
}

table.DTTT_selectable tbody tr {
  cursor: pointer;
  *cursor: hand;
}

div.DTTT .btn {
  color: #333 !important;
  font-size: 0.9em;
}

div.DTTT .btn:hover {
  text-decoration: none !important;
}

ul.DTTT_dropdown.dropdown-menu li:hover a {
  background-color: #08c;
  color: white !important;
}

div.DTTT_print_info.modal {
  height: 150px;
  margin-top: -75px;
  text-align: center;
}

div.DTTT_print_info h6 {
  font-size: 28px;
  font-weight: normal;
  line-height: 28px;
  margin: 1em;
}

div.DTTT_print_info p {
  font-size: 14px;
  line-height: 20px;
}

/*
 File upload buttons
 http://jasny.github.com/bootstrap/javascript.html
*/
.btn-file {
  overflow: hidden;
  position: relative;
  vertical-align: middle;
}

.btn-file > input {
  cursor: pointer;
  direction: ltr;
  filter: alpha(opacity=0);
  font-size: 23px;
  margin: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(-300px, 0) scale(4);
}

.fileupload {
  margin-bottom: 9px;
}

.fileupload .uneditable-input {
  cursor: text;
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
}

.fileupload .thumbnail {
  display: inline-block;
  margin-bottom: 5px;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
}

.fileupload .thumbnail > img {
  display: inline-block;
  max-height: 100%;
  vertical-align: middle;
}

.fileupload .btn {
  vertical-align: middle;
  position: relative;
}

.fileupload-field {
  overflow: hidden;
  height: 0;
  width: 0;
  opacity: 0;
  input[type="file"] {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: red;
  }
}

.fileupload-exists .fileupload-new,
.fileupload-new .fileupload-exists {
  display: none;
}

.fileupload-inline .fileupload-controls {
  display: inline;
}

.fileupload .fileupload-preview-container {
  background: colors.$gray200;
  border-color: #ccc;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
  border-style: solid;
  border-width: 1px 0 1px 1px;
  display: inline-block;
  float: left;
  height: 16px;
  line-height: 16px;
  margin-left: 0;
  padding: 8px 16px 8px 8px;
  width: 140px;
}

.control-group.error .fileupload .fileupload-preview-container {
  border-color: #b94a48;
}

.fileupload .fileupload-preview {
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
}

.fileupload-new .input-append .btn-file {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}

.fileupload-cancel-change {
  display: inline-block;
  line-height: 34px;
  margin-left: -14px;
  vertical-align: middle;
}

.image-control-group .fileupload {
  margin-bottom: 0;
}

.image-control-group .fileimage {
  margin-bottom: 0.5em;
}

/*
 container around a file upload input that hides the text input that normally
 accompanies the button
 http://stackoverflow.com/questions/8350927/file-upload-button-without-input-field
*/
.button--fileuploadOnly {
  height: 34px;
  padding: 0;
}

.fileuploadOnlyForm {
  margin: 0;
}

.fileuploadOnlyButton {
  height: 34px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.fileuploadOnlyButtonText {
  padding: 0 15px;
}

.fileuploadOnlyButton input {
  cursor: pointer;
  filter: alpha(opacity=0);
  font-size: 300px;
  height: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.dropdown-menu form {
  margin: 0;
}

.dropdown-menu .fileuploadOnlyButton {
  height: 20px;
  padding: 6px 15px;
  white-space: nowrap;
  width: auto;
}

.dropdown-menu .fileuploadOnlyButton:hover {
  background-color: #eee;
}

// Hack to remove standard Intercom launcher
// and notification messages that pop up, even
// though we're using a custom launcher...
.intercom-lightweight-app,
.intercom-launcher-frame,
.intercom-launcher-badge-frame,
[data-testid="active-notifications"]{
  visibility: hidden !important;
}

iframe[name='intercom-borderless-frame'],
iframe[name='intercom-notifications-frame'] {
  bottom: 6px !important;
  right: 15px !important;
}

iframe[name='intercom-note-frame'] {
  bottom: 23px !important;
  right: 23px !important;
}
