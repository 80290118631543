@use 'colors';
@use "bootstrap-variables";

.UploadsInfoPane {
  background-color: bootstrap-variables.$white;
  border-left: 5px solid colors.$gray400;
  padding: 16px;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
