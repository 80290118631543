@use 'typography';
@use 'colors';

.select2-search {
  height: 32px;
  margin-bottom: 3px;
  margin-top: 3px;

  input {
    border-radius: 0 !important;
    height: 32px !important;
  }
}

.select2-custom {
  border: 1px solid #9a9a9a;
  border-radius: 4px 4px 4px 4px;
  box-shadow: 0 10px 16px -2px rgba(0, 0, 0, 0.5);
  margin-top: 3px;
  max-width: 248px;
  min-width: 248px;

  .select2-disabled {
    background: #fff;
    color: #999;
    pointer-events: none;
  }

  .select2-results {
    margin: 4px 0 4px 0;
    max-height: 300px;
    padding: 0 0 0 0;
  }

  .select2-results .select2-result-label {
    cursor: pointer;
    margin: 0;
    padding: 6px 15px 6px;
  }

  .select2-results .select2-highlighted {
    background: #eee;
    color: #000;
  }

  .select2-results .select2-no-results {
    background: #fff;
    padding: 6px 15px 6px;
  }

  .select2-results li.select2-result-with-children > .select2-result-label {
    /* results type labels */
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.25), inset 0 0 0 rgba(0, 0, 0, 0.1);
    color: #999;
    font-size: 11px;
    font-style: 700;
    margin-top: 5px;
    text-transform: uppercase;
  }

  .select2-results
    li.select2-result-with-children:first-child
    > .select2-result-label {
    box-shadow: none;
    margin-top: 0;
  }
}

#content {
  .select2-container {
    margin-right: 5px;

    .select2-choice {
      background-color: #fff;
      background-image: linear-gradient(to bottom, #fff 0%, #fff 50%);
      border: 1px solid colors.$gray600;
      border-radius: 3px;
      color: #1f323a;
      font-family: typography.$font-family;
      font-size: 14px;
      height: 24px;
      line-height: 1.618;
      max-width: 248px;
      padding: 4px 0 4px 11px; /* padding to main selected title */
    }

    .select2-choicen {
      display: block;
      margin-right: 31px;
      overflow: hidden;
      padding-bottom: 1px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  /* =======
     results */

  .select2-result-label {
    /* added to create ellipsis for results */
    display: block;
    max-width: 248px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .select2-container .select2-choice .select2-arrow {
    background: #fff;
    background-image: linear-gradient(to bottom, #fff 0%, #fff 60%);
    border-left: none;
    margin-right: 5px;
    margin-top: -3px;
  }

  .select2-container .select2-choice .select2-arrow b {
    background: none;
    background-image: none !important;
    display: block;
    height: 100%;
    margin-left: 5px;
    margin-top: 8px;
    width: 100%;
  }

  .select2-container .select2-choice .select2-arrow b:before {
    content: '\f0d7';
    font-family: "Font Awesome 5 Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
  }

  .select2-search {
    margin-bottom: 0;
    margin-left: 1px; /* in combination with width provides proper left/right spacing */
    margin-top: 5px;
    min-height: 30px;
    padding-top: 4px;
    width: 244px;
  }

  .select2-dropdown-open .select2-choice,
  .select2-dropdown-active .select2-choice {
    /* main button bkg */
    background-color: colors.$gray700;
    background-image: linear-gradient(to bottom, colors.$gray700 0%, colors.$gray700 100%);
    border: none;
    border-bottom-color: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0 0 0 #fff;
    box-shadow: 0 8px 6px -8px rgba(0, 0, 0, 0.2);
    color: #fff;
  }

  .select2-dropdown-open .select2-choice .select2-arrow {
    /* caret bkg */
    background: colors.$gray700;
    border-left: none;
  }

  /* ======
     search */

  .select2-search input {
    background: none;
    border-radius: 2px 2px 0 0;
    box-shadow: inset 0 12px 10px -15px rgba(0, 0, 0, 0.8);
    color: colors.$gray700;
    font-family: typography.$font-family;
    font-size: 14px;
    height: 24px;
    padding: 6px 26px 6px 6px;
  }

  .select2-search {
    /* offset adjustment to select2-results */
    margin-bottom: 3px;
  }

  // header navigation to different objects
  .page-header {
    .select2-choice,
    .select2-chosen {
      overflow: visible;
    }
  }
}

.select2-hidden-accessible {
  display: none;
}

/* removes gray down arrow on hi-dpi */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-resolution: 144dpi) {
  .page-header,
  .graph-pills {
    .select2-search input,
    .select2-search-choice-close,
    .select2-container .select2-choice abbr,
    .select2-container .select2-choice .select2-arrow b {
      background-image: none !important;
    }
  }
}
