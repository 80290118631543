@use 'colors';

$progress-bar-color: colors.$blue300;

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: $progress-bar-color;
  height: 2px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1031;
}

/* Fancy blur effect */
#nprogress .peg {
  box-shadow: 0 0 10px $progress-bar-color, 0 0 5px $progress-bar-color;
  display: block;
  height: 100%;
  opacity: 1;
  position: absolute;
  right: 0;
  transform: rotate(3deg) translate(0, -4px);
  width: 100px;
}
