@use 'colors';

.pointNav {
  display: inline;
  margin: 0;

  small.navBuildings {
    font-size: 18px;
    font-weight: 500;
  }
}

#content .simple {
  .select2-container {
    margin-right: 5px;
    vertical-align: baseline;

    .select2-choice {
      background: none;
      border: none;
      border-radius: 0;
      box-shadow: none;
      font-family: inherit;
      font-size: 32px;
      height: 25px;
      line-height: 24px;
      max-width: none;
      padding: 0;

      span {
        color: #222;
        font-size: 30px;
      }

      .select2-arrow {
        background: none;
        margin-right: 0;
        width: 16px;

        b {
          margin-top: -2px;
        }

        b:before {
          content: '\f0dc';
        }
      }

      .select2-chosen {
        font-weight: 500;
        margin-right: 16px;
      }
    }
  }

  small .select2-container {
    margin-top: -6px;

    .select2-choice {
      height: 20px;

      span {
        color: colors.$gray700;
        font-size: 18px;
      }
    }
  }
}

.simple.select2-drop {
  border: 1px solid #97a1a5;
  box-shadow: 10px 10px 15px -5px rgba(0, 0, 0, 0.8);

  .select2-search input {
    background: none;
  }
}
