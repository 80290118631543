@use "bootstrap-variables";
@use "mixins";

@use "sass:math";
// Typography.less
// Headings, body text, lists, code, and more for a versatile and durable typography system
// ----------------------------------------------------------------------------------------


// BODY TEXT
// ---------

p {
  margin: 0 0 math.div(bootstrap-variables.$baseLineHeight, 2);
  small {
    font-size: bootstrap-variables.$baseFontSize - 2;
    color: bootstrap-variables.$grayLight;
  }
}
.lead {
  margin-bottom: bootstrap-variables.$baseLineHeight;
  font-size: 20px;
  font-weight: 200;
  line-height: bootstrap-variables.$baseLineHeight * 1.5;
}

// HEADINGS
// --------

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-family: bootstrap-variables.$headingsFontFamily;
  font-weight: bootstrap-variables.$headingsFontWeight;
  color: bootstrap-variables.$headingsColor;
  text-rendering: optimizelegibility; // Fix the character spacing for headings
  small {
    font-weight: normal;
    color: bootstrap-variables.$grayLight;
  }
}
h1 {
  font-size: 30px;
  line-height: bootstrap-variables.$baseLineHeight * 2;
  small {
    font-size: 18px;
  }
}
h2 {
  font-size: 24px;
  line-height: bootstrap-variables.$baseLineHeight * 2;
  small {
    font-size: 18px;
  }
}
h3 {
  font-size: 18px;
  line-height: bootstrap-variables.$baseLineHeight * 1.5;
  small {
    font-size: 14px;
  }
}
h4, h5, h6 {
  line-height: bootstrap-variables.$baseLineHeight;
}
h4 {
  font-size: 14px;
  small {
    font-size: 12px;
  }
}
h5 {
  font-size: 12px;
}
h6 {
  font-size: 11px;
  color: bootstrap-variables.$grayLight;
  text-transform: uppercase;
}

// Page header
// .page-header {
//   padding-bottom: $baseLineHeight - 1;
//   margin: $baseLineHeight 0;
//   border-bottom: 1px solid $grayLighter;
// }
// .page-header h1 {
//   line-height: 1;
// }



// LISTS
// -----

// Unordered and Ordered lists
ul, ol {
  padding: 0;
  margin: 0 0 math.div(bootstrap-variables.$baseLineHeight, 2) 25px;
}
ul ul, ul ol, ol ol, ol ul {
  margin-bottom: 0;
}
ul {
  list-style: disc;
}
ol {
  list-style: decimal;
}
li {
  line-height: bootstrap-variables.$baseLineHeight;
}
ul.unstyled, ol.unstyled {
  margin-left: 0;
  list-style: none;
}

// Description Lists
dl {
  margin-bottom: bootstrap-variables.$baseLineHeight;
}
dt, dd {
  line-height: bootstrap-variables.$baseLineHeight;
}
dt {
  font-weight: bold;
  line-height: bootstrap-variables.$baseLineHeight - 1; // fix jank Helvetica Neue font bug
}
dd {
  margin-left: math.div(bootstrap-variables.$baseLineHeight, 2);
}
// Horizontal layout (like forms)
.dl-horizontal {
  dt {
    float: left;
    width: 120px;
    clear: left;
    text-align: right;
    @include mixins.text-overflow();
  }
  dd {
    margin-left: 130px;
  }
}

// MISC
// ----

// Horizontal rules
hr {
  margin: bootstrap-variables.$baseLineHeight 0;
  border: 0;
  border-top: 1px solid bootstrap-variables.$hrBorder;
  border-bottom: 1px solid bootstrap-variables.$white;
}

// Emphasis
strong {
  font-weight: bold;
}
em {
  font-style: italic;
}
.muted {
  color: bootstrap-variables.$grayLight;
}

// Abbreviations and acronyms
abbr[title] {
  cursor: help;
  border-bottom: 1px dotted bootstrap-variables.$grayLight;
}

abbr.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

// Blockquotes
blockquote {
  padding: 0 0 0 15px;
  margin: 0 0 bootstrap-variables.$baseLineHeight;
  border-left: 5px solid bootstrap-variables.$grayLighter;
  p {
    margin-bottom: 0;
    @include mixins.font-shorthand(16px,300,bootstrap-variables.$baseLineHeight * 1.25);
  }
  small {
    display: block;
    line-height: bootstrap-variables.$baseLineHeight;
    color: bootstrap-variables.$grayLight;
    &:before {
      content: '\2014 \00A0';
    }
  }

  // Float right with text-align: right
  &.pull-right {
    float: right;
    padding-right: 15px;
    padding-left: 0;
    border-right: 5px solid bootstrap-variables.$grayLighter;
    border-left: 0;
    p, small {
      text-align: right;
    }
  }
}

// Quotes
q:before, q:after, blockquote:before, blockquote:after {
  content: "";
}

// Addresses
address {
  display: block;
  margin-bottom: bootstrap-variables.$baseLineHeight;
  font-style: normal;
  line-height: bootstrap-variables.$baseLineHeight;
}

// Misc
small {
  font-size: 100%;
}
cite {
  font-style: normal;
}
