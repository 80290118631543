@use 'colors';

.footer {
  border-top: 4px solid colors.$gray300;
  padding: 32px 26px;
  text-align: right;
}

.footer > div {
  float: right;
}

.footer > div:last-child {
  clear: both;
}

.footer-a,
[class^='footer-a--'] {
  color: colors.$gray500;
  display: inline-block;
  font-size: 12px;
}

.footer-partner-logo {
  float: left;
  height: 30px;
  max-width: 150px;
}

.footer-a--strong {
  color: colors.$gray900;
  font-weight: 700;
}

.footer-divider {
  color: colors.$gray400;
  display: inline-block;
  font-size: 12px;
  padding-left: 3px;
  padding-right: 3px;
}

.footer-mapbox {
  color: colors.$gray500;
  font-size: 10px;
}

@media (max-width: 479px) {
  .footer > div {
    clear: both;
    float: left;
  }

  .footer-partner-logo {
    margin-bottom: 18px;
  }
}
