@use 'colors';

/*
 * This assumes that the following styles will be inherited from
 * Bootstrap:
 *
 * table tag
 * table .spanN classes
 *
 * See bootstrap/_tables.scss for details.
 */

// TABLE WRAPPER
// Provides rounding around table; I tried to hack this
// with border styles but won't work with rounded corners + hover,
// plus border-collapse is a little too non-deterministic
// --------------------------------------------------------------

.ltable-counter {
  color: colors.$gray600;
  margin-bottom: 16px;
  margin-top: 30px;
  min-height: 15px;
  overflow: hidden;
  position: relative;

  &:first-child {
    // .ContainerMain already has padding 23px
    margin-top: 7px;
  }

  span {
    bottom: 0;
    position: absolute;
  }
}

.ltable-wrapper {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 2px 0 rgba(0.1, 0.15, 0.2, 0.12),
    0 2px 2px 0 rgba(0.1, 0.15, 0.2, 0.24);

  /* border: 1px solid $gray300; */
  padding: 24px 0 0 0;

  @media (min-width: 960px) {
    padding: 24px 16px 16px 16px;
  }
  
  .ltable {
    border: none;
  }

  .ltable--condensed {
    margin: -14px 0;
  }

  .ltable thead th {
    padding-top: 0;
    vertical-align: bottom;
    white-space: inherit;
  }
}

.ltable-scrollable-wrapper {
  overflow-x: scroll;
}

.ltable {
  background-color: white;
  border-collapse: collapse;
  border-radius: 5px;
  border-spacing: 0;
  table-layout: auto;
  width: 100%;
  min-width: 100%;

  th,
  td {
    font-size: 14px;
    line-height: 22px;
    padding: 6px 10px;
    text-align: left;
    vertical-align: top;

    @media (min-width: 1025px) {
      padding: 12px 20px;
    }

    // Dropdown
    a[data-toggle='dropdown']:after {
      background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDIyIDIyIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6IzcxN2I3Zjt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmRyb3AtZG93bjwvdGl0bGU+PGcgaWQ9IlBhZ2UtMSI+PGcgaWQ9IkFydGJvYXJkLTEtQ29weS01Ij48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0xMSwxNC4yNWEwLjk5NjgsMC45OTY4LDAsMCwxLS43MDctMC4yOTNsLTQuNS00LjVBMSwxLDAsMCwxLDcuMjA3LDguMDQzTDExLDExLjgzNTlsMy43OTMtMy43OTNBMSwxLDAsMCwxLDE2LjIwNyw5LjQ1N2wtNC41LDQuNUEwLjk5NjgsMC45OTY4LDAsMCwxLDExLDE0LjI1WiIvPjwvZz48L2c+PC9zdmc+');
      background-position: center;
      background-repeat: no-repeat;
      content: '';
      display: inline-block;
      height: 18px;
      vertical-align: middle;
      width: 18px;
    }
  }

  th {
    font-weight: bold;
    padding: 12px 10px;
    white-space: nowrap;

    @media (min-width: 1025px) {
      padding: 12px 20px;
    }
  }

  thead th {
    border-bottom: 1px solid colors.$gray400;
    vertical-align: bottom;
  }

  tbody td {
    border-right: 1px solid colors.$gray400;

    > a:not(.btn) {
      text-decoration: underline;
    }
  }

  tbody td:last-child {
    border-right: none;
  }

  thead th.col-selector,
  tbody td.col-selector {
    border-right: none;
    padding-left: 10px;
    padding-right: 17px;
    width: 18px;

    & + th {
      padding-left: 0;
    }

    & + td {
      padding-left: 0;
    }
  }

  thead th.col-actions,
  tbody td.col-actions {
    padding: 0 4px;
    position: relative;
    width: 38px;

    & > .dropdown-toggle {
      display: inline-block;
      line-height: 36px;
      text-align: center;
      width: 36px;
    }

    &.open > .dropdown-toggle {
      background-image: none;
      border-radius: 4px;
      box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15),
        0 1px 2px rgba(0, 0, 0, 0.05);
    }
  }

  svg[data-icon='tableIcon-expandRow'] {
    height: 11px;
    width: 11px;
    z-index: 1;
  }
}

.ltable-selected-header {
  color: colors.$gray500;
  margin-bottom: 16px;
}

// CONDENSED TABLE W/ HALF PADDING
// -------------------------------

.ltable--condensed {
  th,
  td {
    padding: 6px 10px;
  }
}

// TABLE W/FIXED LAYOUT (must set headers)
// ---------------------------------------
.ltable--fixed {
  table-layout: fixed !important;

  &.ltable--singleline {
    td {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

// SINGLE-LINE/TRUNCATE
// -----------
.ltable--singleline {
  tbody {
    th,
    td {
      vertical-align: middle;
    }

    th {
      white-space: nowrap;
    }

    th.truncate,
    td.truncate {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    th.col-actions,
    td.col-actions {
      overflow: visible;
    }
  }
}

// ZEBRA-STRIPING
// --------------
.ltable--striped {
  tbody {
    tr:nth-child(odd),
    tr:nth-child(odd) td,
    tr:nth-child(odd) th {
      background-color: colors.$gray100;
    }

    tr:nth-child(even),
    tr:nth-child(even) td,
    tr:nth-child(even) th {
      background-color: white;
    }
  }
}

// HOVER EFFECT
// ------------
.ltable {
  tbody tr:hover td,
  tbody tr:hover th {
    background-color: colors.$gray300 !important;
  }
}

// COL TYPES
// ---------
th.col-check,
td.col-check,
th.col-dropdown,
td.col-dropdown {
  width: 20px;
}

th.col-dropdown-wide,
td.col-dropdown-wide {
  width: 100px;
}

th.col-price {
  width: 80px;
}

td.col-price {
  text-align: right;
}

th.col-status,
td.col-status {
  width: 90px;
}

td.col-nowrap {
  white-space: nowrap;
}

td.col-hard-wrap {
  overflow-wrap: break-word;
  white-space: normal;
  word-wrap: break-word;
}

td.no-right-border {
  border-right: none !important;
}

// Data Manager rows (missing, spikes, etc)
// -----------
.flagged-row td {
  background-color: #fff7dd !important;
}

.flagged-row td:first-child,
.flagged-row--nobackground td:first-child {
  background-clip: padding-box;
  position: relative;
}

.flagged-row .marker,
.flagged-row--nobackground .marker {
  background-color: #f15f4b;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 6px;
}

.flagged-row .marker-spike {
  background-color: #9781e2;
}

.flagged-row .marker-flatline,
.flagged-row .marker-duplicate,
.flagged-row--nobackground .marker-duplicate {
  background-color: #ffcb30;
}

// DATA ERROR ROW
// -----------
.data-error td:last-child:after {
  color: #f66464;
  content: '\f06a';
  float: right;
  font-family: "Font Awesome 5 Pro";
}

// the expanded row error messages
.extended ul.messages {
  list-style: none;
}

.extended .error-message:before {
  color: #f66464;
  content: '\f06a';
  float: left;
  font-family: "Font Awesome 5 Pro";
  margin-left: -16px;
}
