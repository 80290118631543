@use "bootstrap-variables";
@use "colors";
@use "mixins";

@use 'sass:color';
// BUTTON STYLES
// -------------


// Base styles
// --------------------------------------------------

// Core
.btn {
  display: inline-block;
  padding: 4px 10px 4px;
  margin-bottom: 0; // For input.btn
  font-size: bootstrap-variables.$baseFontSize;
  line-height: bootstrap-variables.$baseLineHeight;
  color: bootstrap-variables.$grayDark;
  text-align: center;
  text-shadow: 0 1px 1px rgba(255,255,255,.75);
  vertical-align: middle;
  cursor: pointer;
  @include mixins.buttonBackground(bootstrap-variables.$btnBackground, bootstrap-variables.$btnBackgroundHighlight);
  & {
    border: 1px solid bootstrap-variables.$btnBorder;
    border-bottom-color: color.adjust(bootstrap-variables.$btnBorder, $lightness: -10%);
    border-radius: 4px;
    @include mixins.box-shadow(#{inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05)});
  }
}

// Hover state
.btn:hover {
  color: bootstrap-variables.$grayDark;
  text-decoration: none;
  background-color: color.adjust(colors.$white, $lightness: -10%);
  background-position: 0 -15px;

  // transition is only when going to hover, otherwise the background
  // behind the gradient (there for IE<=9 fallback) gets mismatched
  @include mixins.transition(background-position .1s linear);
}

// Focus state for keyboard and accessibility
.btn:focus {
  @include mixins.tab-focus();
}

// Active state
.btn.active, .btn:active {
  background-color: color.adjust(colors.$white, $lightness: -10%);
  background-image: none;
  outline: 0;
  @include mixins.box-shadow(#{inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05)});
}

// Disabled state
.btn.disabled, .btn[disabled] {
  cursor: default;
  background-color: color.adjust(colors.$white, $lightness: -10%);
  background-image: none;
  @include mixins.opacity(0.65);
  @include mixins.box-shadow(none);
}


// Button Sizes
// --------------------------------------------------

// Large
.btn-large {
  padding: 9px 14px;
  font-size: bootstrap-variables.$baseFontSize + 2px;
  line-height: normal;
  @include mixins.border-radius(5px);
}
.btn-large [class^="icon-"] {
  margin-top: 1px;
}

// Small
.btn-small {
  padding: 5px 9px;
  font-size: bootstrap-variables.$baseFontSize - 2px;
  line-height: bootstrap-variables.$baseLineHeight - 2px;
}
.btn-small [class^="icon-"] {
  margin-top: -1px;
}

// Mini
.btn-mini {
  padding: 2px 6px;
  font-size: bootstrap-variables.$baseFontSize - 2px;
  line-height: bootstrap-variables.$baseLineHeight - 4px;
}


// Alternate buttons
// --------------------------------------------------

// Set text color
// -------------------------
.btn-primary, .btn-primary:hover, .btn-warning, .btn-warning:hover, .btn-danger, .btn-danger:hover, .btn-success, .btn-success:hover, .btn-info, .btn-info:hover, .btn-inverse, .btn-inverse:hover {
  color: colors.$white;
  text-shadow: 0 -1px 0 rgba(0,0,0,.25);
}
// Provide *some* extra contrast for those who can get it
.btn-primary.active, .btn-warning.active, .btn-danger.active, .btn-success.active, .btn-info.active, .btn-inverse.active {
  color: rgba(255,255,255,.75);
}

// Set the backgrounds
// -------------------------
.btn {
  // reset here as of 2.0.3 due to Recess property order
  border-color: #ccc;
  border-color: rgba(0,0,0,.1) rgba(0,0,0,.1) rgba(0,0,0,.25);
}
.btn-primary {
  @include mixins.buttonBackground(bootstrap-variables.$btnPrimaryBackground, bootstrap-variables.$btnPrimaryBackgroundHighlight);
}
// Warning appears are orange
.btn-warning {
  @include mixins.buttonBackground(bootstrap-variables.$btnWarningBackground, bootstrap-variables.$btnWarningBackgroundHighlight);
}
// Danger and error appear as red
.btn-danger {
  @include mixins.buttonBackground(bootstrap-variables.$btnDangerBackground, bootstrap-variables.$btnDangerBackgroundHighlight);
}
// Success appears as green
.btn-success {
  @include mixins.buttonBackground(bootstrap-variables.$btnSuccessBackground, bootstrap-variables.$btnSuccessBackgroundHighlight);
}
// Info appears as a neutral blue
.btn-info {
  @include mixins.buttonBackground(bootstrap-variables.$btnInfoBackground, bootstrap-variables.$btnInfoBackgroundHighlight);
}
.btn-inverse {
  @include mixins.buttonBackground(bootstrap-variables.$btnInverseBackground, bootstrap-variables.$btnInverseBackgroundHighlight);
}
