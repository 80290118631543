@use "bootstrap-variables";

// Scaffolding
// Basic and global styles for generating a grid system, structural layout, and page templates
// -------------------------------------------------------------------------------------------


// Body reset
// -----------------

body {
  margin: 0;
  font-family: bootstrap-variables.$baseFontFamily;
  font-size: bootstrap-variables.$baseFontSize;
  line-height: bootstrap-variables.$baseLineHeight;
  color: bootstrap-variables.$textColor;
  background-color: bootstrap-variables.$bodyBackground;
}


// Links
// -----

a {
  color: bootstrap-variables.$linkColor;
  text-decoration: none;
}
a:hover {
  color: bootstrap-variables.$linkColorHover;
  text-decoration: underline;
}