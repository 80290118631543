@use "bootstrap-variables";
@use "colors";
@use "mixins";

// POPOVERS
// --------

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: bootstrap-variables.$zindexPopover;
  display: none;
  padding: 5px;
  &.top    { margin-top:  -5px; }
  &.right  { margin-left:  5px; }
  &.bottom { margin-top:   5px; }
  &.left   { margin-left: -5px; }
  &.top .arrow    { @include mixins.popoverArrowTop(); }
  &.right .arrow  { @include mixins.popoverArrowRight(); }
  &.bottom .arrow { @include mixins.popoverArrowBottom(); }
  &.left .arrow   { @include mixins.popoverArrowLeft();  }
  .arrow {
    position: absolute;
    width: 0;
    height: 0;
  }
}
.popover-inner {
  padding: 3px;
  width: 280px;
  overflow: hidden;
  background: colors.$black; // has to be full background declaration for IE fallback
  background: rgba(0,0,0,.8);
  @include mixins.border-radius(6px);
  @include mixins.box-shadow(0 3px 7px rgba(0,0,0,0.3));
}
.popover-title {
  padding: 9px 15px;
  line-height: 1;
  background-color: #f5f5f5;
  border-bottom:1px solid #eee;
  @include mixins.border-radius(3px 3px 0 0);
}
.popover-content {
  padding: 14px;
  background-color: colors.$white;
  @include mixins.border-radius(0 0 3px 3px);
  @include mixins.background-clip(padding-box);
  p, ul, ol {
    margin-bottom: 0;
  }
}