@keyframes spinner-animation {
  to {
    transform: rotate(1turn);
  }
}

.progress-spinner {
  animation: spinner-animation 1s infinite steps(8);
  font-size: 12px;
  height: 5em;
  left: calc(50% - 2.5em);
  overflow: hidden;
  position: fixed;
  text-indent: 999em;
  top: calc(50% - 2.5em);
  width: 5em;
}

.small.progress-spinner {
  font-size: 6px;
}

.large.progress-spinner {
  font-size: 24px;
}

.progress-spinner:before,
.progress-spinner:after,
.progress-spinner > div:before,
.progress-spinner > div:after {
  background: #eee;
  border-radius: 0.2em;
  box-shadow: 0 3.5em #eee; /* container height - part height */
  content: '';
  height: 1.5em;
  left: 2.25em; /* (container width - part width)/2  */
  position: absolute;
  top: 0;
  transform-origin: 50% 2.5em; /* container height / 2 */
  width: 0.5em;
}

.progress-spinner:before {
  background: #555;
}

.progress-spinner:after {
  background: #777;
  transform: rotate(-45deg);
}

.progress-spinner > div:before {
  background: #999;
  transform: rotate(-90deg);
}

.progress-spinner > div:after {
  background: #bbb;
  transform: rotate(-135deg);
}
