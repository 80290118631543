@use "bootstrap-variables";
@use "mixins";

// PORTRAIT TABLET TO DEFAULT DESKTOP
// ----------------------------------

@media (min-width: 768px) and (max-width: 979px) {

  // Fixed grid
  @include mixins.gridCore(bootstrap-variables.$gridColumnWidthTablet, bootstrap-variables.$gridGutterWidthTablet);

  // Fluid grid
  @include mixins.gridFluid(bootstrap-variables.$fluidGridColumnWidthTablet, bootstrap-variables.$fluidGridGutterWidthTablet);

  // Input grid
  @include mixins.gridInput(bootstrap-variables.$gridColumnWidthTablet, bootstrap-variables.$gridGutterWidthTablet);

  // No need to reset .thumbnails here since it's the same $gridGutterWidth
}