@use "mixins";

// Reset.css.scss
// Adapted from Normalize.css http://github.com/necolas/normalize.css
// ------------------------------------------------------------------------

// Display in IE6-9 and FF3
// -------------------------

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
  display: block;
}


// Prevents modern browsers from displaying 'audio' without controls
// -------------------------

audio:not([controls]) {
    display: none;
}

// Base settings
// -------------------------

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
}
// Focus states
a:focus {
  @include mixins.tab-focus();
}
// Hover & Active
a:hover, a:active {
  outline: 0;
}

// Prevents sub and sup affecting line-height in all browsers
// -------------------------

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}

// Img border in a's and image quality
// -------------------------

img {
  max-width: 100%; // Make images inherently responsive
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic;
}

// Prevent max-width from affecting Google Maps
#map_canvas img {
  max-width: none;
}

// Forms
// -------------------------

// Font size in all browsers, margin changes, misc consistency
button, input, select, textarea {
  margin: 0;
  font-size: 100%;
  vertical-align: middle;
}
button, input {
  line-height: normal; // FF3/4 have !important on line-height in UA stylesheet
}
button::-moz-focus-inner, input::-moz-focus-inner { // Inner padding and border oddities in FF3/4
  padding: 0;
  border: 0;
}
button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer; // Cursors on all buttons applied consistently
  -webkit-appearance: button; // Style clickable inputs in iOS
}
input[type="search"] { // Appearance in Safari/Chrome
  -webkit-box-sizing: content-box;
     -moz-box-sizing: content-box;
          box-sizing: content-box;
  -webkit-appearance: textfield;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none; // Inner-padding issues in Chrome OSX, Safari 5
}
textarea {
  overflow: auto; // Remove vertical scrollbar in IE6-9
  vertical-align: top; // Readability and alignment cross-browser
}
