@use "bootstrap-variables";
@use "mixins";

// ALERT STYLES
// ------------

// Base alert styles
.alert {
  padding: 8px 35px 8px 14px;
  margin-bottom: bootstrap-variables.$baseLineHeight;
  text-shadow: 0 1px 0 rgba(255,255,255,.5);
  background-color: bootstrap-variables.$warningBackground;
  border: 1px solid bootstrap-variables.$warningBorder;
  @include mixins.border-radius(4px);
  color: bootstrap-variables.$warningText;
}
.alert-heading {
  color: inherit;
}

// Adjust close link position
.alert .close {
  position: relative;
  top: -2px;
  right: -21px;
  line-height: 18px;
}

// Alternate styles
// ----------------

.alert-success {
  background-color: bootstrap-variables.$successBackground;
  border-color: bootstrap-variables.$successBorder;
  color: bootstrap-variables.$successText;
}
.alert-danger, .alert-error {
  background-color: bootstrap-variables.$errorBackground;
  border-color: bootstrap-variables.$errorBorder;
  color: bootstrap-variables.$errorText;

  a {
    color: bootstrap-variables.$errorText;
    text-decoration: underline;
  }
}
.alert-info {
  background-color: bootstrap-variables.$infoBackground;
  border-color: bootstrap-variables.$infoBorder;
  color: bootstrap-variables.$infoText;
}

// Block alerts
// ------------------------
.alert-block {
  padding-top: 14px;
  padding-bottom: 14px;
}
.alert-block > p, .alert-block > ul {
  margin-bottom: 0;
}
.alert-block p + p {
  margin-top: 5px;
}
