@use 'colors';
@use 'shadows';
@use "bootstrap-variables";

/**
* Pure CSS collapsible section. Expanded state stored in an invisible checkbox
*
* Markup:
* <div class='CollapsibleSection'>
*   <input id='unique-id' type='checkbox'>
*   <label for='unique-id'>
*     Section Title
*     <span class='CollapsibleSectionStatus'>
*       Status text (this tag is optional)
*       {{ svgSymbol('status-XXXXX') }}
*     </span>
*   </label>
*   <div class='CollapsibleSectionBody'>
*     <div class='CollapsibleSectionBodyHeader'> ...
*     </div>
*     ...
*     <div class='CollapsibleSectionBodyFooter'> ...
*     </div>
*   </div>
* </div>
*/
.CollapsibleSection {
  background-color: bootstrap-variables.$white;
  border-radius: 5px;
  box-shadow: shadows.$panel-box-shadow;
  margin: 16px 0;
  position: relative;

  &:first-child {
    margin-top: 0;
  }

  > input {
    display: none;
  }

  > input + label {
    cursor: pointer;
    margin: 0;
    padding: 16px;

    .CollapsibleSectionStatus {
      color: colors.$gray500;
      float: right;
      font-weight: normal;

      .svg-symbol {
        height: 16px;
        margin-left: 4px;
        vertical-align: middle;
        width: 16px;
      }
    }
  }

  > input + label > .svg-symbol {
    height: 12px;
    margin-right: 6px;
    width: 12px;
  }

  > input + label .helptext-tooltip {
    padding: 0 10px;
    position: absolute;

    .svg-symbol {
      height: 20px;
    }
  }

  > input:checked + label.CollapsibleSectionHeader {
    border-bottom: 1px solid colors.$gray200;
  }

  > input:checked + label > [data-icon=arrow-accordion] {
    transform: rotate(90deg) translateX(2px);
  }

  > input + label + .CollapsibleSectionBody {
    display: none;
  }

  > input:checked + label + .CollapsibleSectionBody {
    display: block;
  }
}

.CollapsibleSectionBody {
  &--padding {
    padding: 20px;
  }

  > div {
    padding: 0 20px 20px 20px;
  }

  .CollapsibleSectionBodyButtonBar {
    margin-bottom: 20px;
    padding: 0;
    text-align: right;

    &:last-child {
      margin-bottom: 0;
      text-align: center;
    }

    > * {
      display: inline-block;
      margin-left: 12px;
    }
  }

  .svg-symbol {
    margin-right: 4px;
    vertical-align: middle;
  }

  .Button .svg-symbol {
    stroke: colors.$gray500;
  }

  .Button--green .svg-symbol {
    stroke: none;
  }

  .CollapsibleSectionBodyHeader {
    border-bottom: 1px solid colors.$gray300;
    color: colors.$gray600;
    padding: 0 20px 20px 37px;
  }

  .CollapsibleSectionBodyHeader + div {
    padding-top: 20px;
  }

  .CollapsibleSectionBodyFooter {
    border-top: 1px solid colors.$gray300;
    padding-bottom: 0;
    padding-top: 20px;
  }

  .CollapsibleSectionBodyStatus {
    margin: 50px 0;
    text-align: center;
  }

  .CollapsibleSectionBodyMessage {
    border-bottom: 1px solid colors.$gray300;
    border-top: 1px solid colors.$gray300;
    display: table;
    min-height: 60px;
    padding: 0;
    width: 100%;

    > * {
      display: table-cell;
      padding: 16px 0;
    }

    label {
      width: 250px;
    }
  }

  .CollapsibleSectionBodyMessage + .CollapsibleSectionBodyMessage {
    border-top: 0;
  }
}

.CollapsibleSectionBodyTable {
  border: 0 !important; /* undo .ContainerMain table */
  table-layout: auto;
  width: 100%;

  a {
    text-decoration: underline;
  }

  th,
  td {
    font-size: 14px;
    line-height: 22px;
    padding: 6px 10px;
    text-align: left;
    vertical-align: top;
  }

  th {
    font-weight: bold;
  }

  thead {
    border-bottom: 1px solid colors.$gray300;
  }

  tbody td {
    border-right: 1px solid colors.$gray300;

    .svg-symbol {
      fill: colors.$gray600;
    }
  }

  tbody td:last-child {
    border-right: 0;
  }

  tbody tr:nth-child(odd) td {
    background-color: colors.$gray100;
  }
}
