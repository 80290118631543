.-sew-list-container {
  background: white;
  border: 1px solid #ddd;
  border-radius: 3px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  min-width: 180px;
}

.-sew-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.-sew-list-item {
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  display: block;
  padding: 5px 10px;
}

.-sew-list-item small {
  color: #afafaf;
}

.-sew-list-item.selected {
  background: #4183c4;
  color: #fff;
  text-decoration: none;
}
