@use 'colors';

/* Note: modified copy from cdnjs.cloudflare.com/ajax/libs/tagmanager/3.0.0/tagmanager.css */
.tm-tag {
  background-color: colors.$gray300;
  border-radius: 16px;
  color: colors.$gray900;
  display: inline-block;
  font-size: 12px;
  margin: 0 10px 0 0;
  padding: 6px 15px;
  text-decoration: none;
  vertical-align: middle;
}

.tm-tag .tm-tag-remove {
  color: colors.$gray400;
  margin-left: 4px;
}

.tm-tag .tm-tag-remove:hover {
  color: colors.$blue300;
  text-decoration: none;
}

.tm-tag.tm-tag-warning {
  background-color: #f2c889;
  border-color: #f0a12f;
  color: #945203;
}

.tm-tag.tm-tag-error {
  background-color: #e69ca6;
  border-color: #d24a5d;
  color: #84212e;
}

.tm-tag.tm-tag-success {
  background-color: #cde69c;
  border-color: #a5d24a;
  color: #638421;
}

.tm-tag.tm-tag-info {
  background-color: #c5eefa;
  border-color: #5dc8f7;
  color: #4594b5;
}

.tm-tag.tm-tag-inverse {
  background-color: #555;
  border-color: #333;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2) inset;
  color: #ccc;
}

.tm-tag.tm-tag-inverse .tm-tag-remove {
  color: #fff;
}

.tm-tag.tm-tag-large {
  border-radius: 4px;
  font-size: 16.25px;
  padding: 11px 7px;
}

.tm-tag.tm-tag-small {
  border-radius: 3px;
  font-size: 11.049999999999999px;
  padding: 2px 4px;
}

.tm-tag.tm-tag-mini {
  border-radius: 2px;
  font-size: 9.75px;
  padding: 0 2px;
}

.tm-tag.tm-tag-plain {
  background: none;
  border: none;
  box-shadow: none;
  color: #333;
}

.tm-tag.tm-tag-disabled {
  background-color: #e6e6e6;
  border-color: #ccc;
  box-shadow: none;
  color: #aaa;
}

.tm-tag.tm-tag-disabled .tm-tag-remove {
  display: none;
}

input[type='text'].tm-input {
  margin-bottom: 5px;
  vertical-align: middle !important;
}

.control-group.tm-group {
  margin-bottom: 5px;
}

.form-horizontal .control-group.tm-group {
  margin-bottom: 15px;
}
