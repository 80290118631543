@use 'colors';
@use "bootstrap-variables";

.StickyHeader {
  background-color: colors.$gray200;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
  // mimic ContainerMain behavior
  max-width: 1600px;
  position: relative;
  
  .StickyHeader-filter, 
  .StickyHeader-filter-tags {
    display: flex;
    flex-wrap: wrap;
    .StickyHeader-control {
      margin: 10px 5px 0;
      line-height: 40px;
      height: 40px;
      button {
        height: 40px;
      }
      &.control-right {
        margin-left: auto;
      }
      &:last-child {
        margin-right: 0;
      }
      &:first-child:not(.control-right) {
        margin-left: 0;
      }
    }
    &.filter-tags-margin {
      margin-top: 10px;
    }
  }
  .StickyHeader-filter { 
    &:not(.StickyHeader-control) {
      margin: -10px 0 0 0;
    }

    &.message--info {
      margin-bottom: 24px;
    }
    .StickyHeader-filter {
      margin-top: 0;
    }
  }

  input[name=q] {
    border-radius: 5px;
    box-shadow: none;
    padding: 11px 11px 11px 32px;
    // undo bootstrap rigid responsiveness
    @media (max-width: 767px) {
      font-size: inherit;
      min-height: 0;
    }
  }

  .form-search + .svg-symbol {
    left: 7px;
    opacity: 0.6;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .btn-group {
    background-color: bootstrap-variables.$white;
    border: 1px solid #ccc;
    border-radius: 5px;
    line-height: 38px;
    margin-left: 10px;
    padding: 0 32px 0 16px;

    a {
      cursor: pointer;
    }

    a:hover {
      text-decoration: none;

      [data-icon=dropdown] {
        fill: colors.$blue300;
        stroke: colors.$blue300;
      }
    }

    .svg-symbol {
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  @for $i from 1 through 5 {
    .multi-column.cols-#{$i} {
      width: #{180 * $i}px;
    }
  }

  // TODO: following are maybe better .Button default rules
  .Button {
    display: flex;
    align-items : center;
    height: 40px;
    line-height: 38px;
    padding: 0 16px;
    position: relative;
    text-align: left;

    &:hover {
      > a {
        color: colors.$blue300;
      }

      [data-icon=dropdown] {
        fill: colors.$blue300;
        stroke: colors.$blue300;
      }
    }

    > a {
      display: block;
      text-align: center;
    }

    a:hover {
      text-decoration: none;
    }

    [data-icon=dropdown] {
      transform: translate(4px, 6px);
    }

    .fa-su {
      margin-bottom: 30px;
      margin-left: 20px;
    }
  }

  .toggle-btn {
    background-color: #fff;
    border: 1px solid #ccc;
    border-right: 0;
    display: table-cell;
    line-height: 38px;
    padding: 0 8px;

    &:first-child {
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
    }

    &:last-child {
      border-bottom-right-radius: 5px;
      border-right: 1px solid #ccc;
      border-top-right-radius: 5px;
    }

    &.active {
      background-color: colors.$gray200;
    }

    .svg-symbol {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &-filter {
    width: inherit;

    form {
      margin: 0;
    }
  }

  &-table {
    height: 65px;
    margin: 0 20px;
    overflow: hidden;
    padding: 0 3px;
    // mimic ContainerMain behavior
    @media (min-width: 1718px) {
      margin-left: 0 !important;
      margin-right: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  & + .ContainerMain {
    padding-top: 0;

    /*
    .ltable-wrapper thead {
      visibility: hidden;
    }
    */
  }

  &.StickyHeader--sticky {
    position: fixed;
    top: 53px;

    .StickyHeader-table {
      box-shadow: 0 4px 4px -2px colors.$gray400;
    }
  }
}
