@use 'colors';

.select2-offscreen + .svg-symbol {
  // select2 has its own dropdown style
  display: none;
}

.svg-symbol {
  height: 24px;
  width: 24px;

  &[data-icon='dropdown'] {
    fill: colors.$gray600;
    height: 20px;
    stroke: colors.$gray600;
    width: 20px;

    &:hover {
      fill: colors.$blue300;
      stroke: colors.$blue300;
    }
  }

  &[data-icon='plus'] {
    height: 22px;
    stroke: colors.$gray500;
    stroke-width: 2px;
    width: 22px;
  }

  &[data-icon='arrow-right'] {
    stroke: colors.$gray600;
  }

  &[data-icon='drop-down'] {
    height: 22px;
    stroke: colors.$gray600;
    stroke-width: 2;
    width: 22px;
  }

  &[data-icon='budget'] {
    height: 16px;
    // not to design spec, override in appropriate stylesheet
    // (without a stroke it's invisible)
    stroke: burlywood;
    stroke-width: 15px;
    width: 16px;
  }

  &[data-icon='status-flatlined'] {
    height: 22px;
    stroke: #717b7f;
    width: 22px;
  }

  &[data-icon='status-disabled'] {
    fill: #bac6cc;
    height: 16px;
    width: 17px;
  }

  &[data-icon='status-derived'] {
  }

  &[data-icon='status-manual'] {
    fill: #fc993f;
    height: 16px;
    width: 17px;
  }

  &[data-icon='status-online'] {
    fill: #8bc53f;
    height: 16px;
    width: 17px;
  }

  &[data-icon='status-offline'] {
    height: 16px;
    width: 17px;
  }

  &[data-icon='status-outdated'] {
    height: 16px;
    width: 17px;
  }

  &[data-icon='status-calculated'] {
    height: 16px;
    width: 17px;
  }

  &[data-icon='status-on-track'] {
    height: 22px;
    width: 22px;
  }

  &[data-icon='status-off-track'] {
    height: 22px;
    width: 22px;
  }

  &[data-icon='status-card-offline'] {
    height: 32px;
    width: 32px;
  }

  &[data-icon='status-card-online'] {
    height: 32px;
    width: 32px;
  }

  &[data-icon='status-card-outdated'] {
    height: 32px;
    width: 32px;
  }

  &[data-icon='column-sorting-ascending'] {
    fill: #717b7f;
  }

  &[data-icon='column-sorting-descending'] {
    fill: #717b7f;
  }

  &[data-icon='delete-white'] {
    fill: white;
  }

  &[data-icon='tableIcon-expandRow'] {
    fill: #717b7f;

    &:hover {
      fill: colors.$blue300;
    }
  }
}

.svg-symbol.inline {
  vertical-align: middle;
}
