@use 'colors';

.navbar-breadcrumbs {
  color: colors.$gray900;
  font-weight: 700;

  a,
  .breadcrumb-divider {
    color: colors.$gray500;
    font-weight: 400;
  }

  .breadcrumb-divider:first-child {
    pointer-events: none;
  }

  .breadcrumb-divider:first-child:before {
    content: '\02026';
    cursor: pointer;
    pointer-events: all;
  }

  @media (max-width: 1024px) {
    .breadcrumb-divider:first-child {
      display: inline;
    }

    a,
    .breadcrumb-divider {
      display: none;
    }
  }

  @media (min-width: 1025px) {
    .breadcrumb-divider:first-child {
      display: none;
    }

    a,
    .breadcrumb-divider {
      display: inline;
    }
  }
}

.breadcrumb-divider {
  display: inline-block;
  font-weight: 400;
  padding-left: 5px;
  padding-right: 5px;
}
