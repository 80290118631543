@use 'bootstrap-variables';

@forward 'bootstrap-variables';

// Color specifications from BOS-10369

$grayscale: #1f323a, #4d575b, #717b7f, #97a1a5, #bac6cc, #e4ebef;
$redpanda: #571810, #91281a, #c73724, #f15f4b, #ff978a, #ffc5bc;
$bengaltyga: #632f00, #994900, #d96800, #fc993f, #ffb470, #fcd9b8;
$honeybee: #523700, #915f00, #d99c00, #ffcb30, #ffde7d, #ffeeba;
$lucid: #244000, #447a00, #6ba819, #8bc53f, #b0d67e, #dbebc5;
$seagrass: #00332c, #007364, #2f9e91, #28baa7, #7de0d3, #bcdbe4;
$bluefintuna: #09374d, #126b96, #3397c7, #3aa9df, #8ecded, #cbebfa;
$seaurchin: #302459, #4c398c, #765fc2, #9781e2, #c7bbed, #eae3ff;
$spectrum: #9781e2, #8bc53f, #ffcb30, #3aa9df, #fc993f, #28baa7, #c7bbed, #b0d67e, #ffde7d, #8ecded, #ffb740, #7de0d3, #ff978a, #e4ebef;
$spectrum-hover: #c7bbed, #b0d67e, #ffde7d, #8ecded, #ffb740, #7de0d3, #eae3ff, #dbebc5, #ffeeba, #cbebfa, #fcd9b8, #bcebe4, #ffc5bc, #f0f5f7;

$red100: #ffc5bc;
$red200: #ff978a;
$red300: #f15f4b;
$red400: #c73724;
$red500: #91281a;
$red600: #571810;

$orange100: #fcd9b8;
$orange200: #ffb740;
$orange300: #fc993f;
$orange400: #d96800;
$orange500: #994900;
$orange600: #632f00;

$yellow100: #ffeeba;
$yellow200: #ffde7d;
$yellow300: #ffcb30;
$yellow400: #d99c00;
$yellow500: #915f00;
$yellow600: #523700;

$green100: #dbebc5;
$green200: #b0d67e;
$green300: #8bc53f;
$green400: #6ba819;
$green500: #447a00;
$green600: #244000;

$teal100: #bcebe4;
$teal200: #7de0d3;
$teal300: #28baa7;
$teal400: #2f9e91;
$teal500: #007364;
$teal600: #00332c;

$blue100: #cbebfa;
$blue200: #8ecded;
$blue300: #3aa9df;
$blue400: #3397c7;
$blue500: #126b96;
$blue600: #09374d;

$purple100: #eae3ff;
$purple200: #c7bbed;
$purple300: #9781e2;
$purple400: #765fc2;
$purple500: #4c398c;
$purple600: #302459;

$gray100: #f9f9f9;
$gray200: #f0f5f7;
$gray300: #e4ebef;
$gray350: #c6d3d9;
$gray400: #bac6cc;
$gray500: #97a1a5;
$gray600: #717b7f;
$gray700: #4d575b;
$gray800: #1f323a;
$gray900: #111b1f;

bootstrap-variables.$black: #000;
$black-modal: #11191f;
bootstrap-variables.$white: #fff;

$shadow01: 0 0 2px 0 rgba(17, 25, 31, 0.12), 0 2px 2px 0 rgba(17, 25, 31, 0.12);
$shadow02: 0 0 4px 0 rgba(17, 25, 31, 0.12), 0 4px 4px 0 rgba(17, 25, 31, 0.12);
$shadow03: 0 0 8px 0 rgba(17, 25, 31, 0.12), 0 8px 8px 0 rgba(17, 25, 31, 0.12);
$shadow04: 0 0 16px 0 rgba(17, 25, 31, 0.12), 0 16px 16px 0 rgba(17, 25, 31, 0.12);
$shadow05: 0 0 32px 0 rgba(17, 25, 31, 0.12), 0 32px 32px 0 rgba(17, 25, 31, 0.12);
$shadow06: 0 0 64px 0 rgba(17, 25, 31, 0.12), 0 64px 64px 0 rgba(17, 25, 31, 0.12);

$shadow01-inset: inset 0 0 2px 0 rgba(17, 25, 31, 0.12), inset 0 2px 2px 0 rgba(17, 25, 31, 0.12);
$shadow02-inset: inset 0 0 4px 0 rgba(17, 25, 31, 0.12), inset 0 4px 4px 0 rgba(17, 25, 31, 0.12);
$shadow03-inset: inset 0 0 8px 0 rgba(17, 25, 31, 0.12), inset 0 8px 8px 0 rgba(17, 25, 31, 0.12);
$shadow04-inset: inset 0 0 16px 0 rgba(17, 25, 31, 0.12), inset 0 16px 16px 0 rgba(17, 25, 31, 0.12);
$shadow05-inset: inset 0 0 32px 0 rgba(17, 25, 31, 0.12), inset 0 32px 32px 0 rgba(17, 25, 31, 0.12);
$shadow06-inset: inset 0 0 64px 0 rgba(17, 25, 31, 0.12), inset 0 64px 64px 0 rgba(17, 25, 31, 0.12);

$dropdownMenuBoxShadow: 0 4px 4px 0 rgba(17, 25, 31, 0.24), 0 4px 4px 0 rgba(17, 25, 31, 0.12);
