@use 'colors';

.featureCell-container {
  display: inline-block;
  height: 207px;
  text-align: center;
  vertical-align: top;
}

.featureCell {
  box-sizing: border-box;
  display: inline-block;
  height: 100%;
  padding: 31px;

  &:hover {
    text-decoration: none;

    img {
      opacity: 0.8;
    }

    image {
      opacity: 0.8;
    }

    .iconCell-name {
      color: colors.$gray800;
    }
  }

  @media (max-width: 768px) {
    margin: 10px;
  }

  @media (max-width: 480px) {
    margin: 0;
  }
}

.featureCell-img {
  height: 100px;
  width: 100px;
}

.featureCell-name {
  display: block;
  font-size: 13px;
  font-weight: 400;
  padding-top: 7px;
  width: 105px;
}
