/**
 * Sizing utilities from SUIT CSS, modified to play nice with Bootstrap and SASS
 */

@use 'size-sm';
@use 'size-md';
@use 'size-lg';

/* Intrinsic widths
   ========================================================================== */

/**
 * Make an element shrink wrap its content.
 */

.u-sizeFit,
.u-sizeFitAlt {
  display: block !important;
  float: left !important;
  width: auto !important;
}

.u-sizeFitAlt {
  float: right !important;
}

/**
 * Make an element fill the remaining space.
 * N.B. This will hide overflow.
 */

.u-sizeFill {
  display: block !important;
  overflow: hidden !important;
  width: auto !important;
}

/**
 * An alternative method to make an element fill the remaining space.
 * N.B. Do not use if child elements might be wider than the remaining space.
 * In Chrome, Safari, and Firefox it results in undesired layout.
 */

.u-sizeFillAlt {
  display: table-cell !important;
  max-width: 100% !important;
  width: 10000px !important;
}

/**
 * Make an element the width of its parent.
 */

.u-sizeFull {
  box-sizing: border-box !important;
  display: block !important;
  width: 100% !important;
}

/* Proportional widths
   ========================================================================== */

/**
 * Specify the proportional width of an object.
 * Intentional redundancy build into each set of unit classes.
 * Supports: 2, 3, 4, 5, 6, 8, 10, 12 part
 */

.u-size1of12 {
  width: calc(100% * 1 / 12) !important;
}

.u-size1of10 {
  width: 10% !important;
}

.u-size1of8 {
  width: 12.5% !important;
}

.u-size1of6,
.u-size2of12 {
  width: calc(100% * 1 / 6) !important;
}

.u-size1of5,
.u-size2of10 {
  width: 20% !important;
}

.u-size1of4,
.u-size2of8,
.u-size3of12 {
  width: 25% !important;
}

.u-size3of10 {
  width: 30% !important;
}

.u-size1of3,
.u-size2of6,
.u-size4of12 {
  width: calc(100% * 1 / 3) !important;
}

.u-size3of8 {
  width: 37.5% !important;
}

.u-size2of5,
.u-size4of10 {
  width: 40% !important;
}

.u-size5of12 {
  width: calc(100% * 5 / 12) !important;
}

.u-size1of2,
.u-size2of4,
.u-size3of6,
.u-size4of8,
.u-size5of10,
.u-size6of12 {
  width: 50% !important;
}

.u-size7of12 {
  width: calc(100% * 7 / 12) !important;
}

.u-size3of5,
.u-size6of10 {
  width: 60% !important;
}

.u-size5of8 {
  width: 62.5% !important;
}

.u-size2of3,
.u-size4of6,
.u-size8of12 {
  width: calc(100% * 2 / 3) !important;
}

.u-size7of10 {
  width: 70% !important;
}

.u-size3of4,
.u-size6of8,
.u-size9of12 {
  width: 75% !important;
}

.u-size4of5,
.u-size8of10 {
  width: 80% !important;
}

.u-size5of6,
.u-size10of12 {
  width: calc(100% * 5 / 6) !important;
}

.u-size7of8 {
  width: 87.5% !important;
}

.u-size9of10 {
  width: 90% !important;
}

.u-size11of12 {
  width: calc(100% * 11 / 12) !important;
}
