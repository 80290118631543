@use "bootstrap-variables";
@use "colors";
@use "mixins";

@use 'sass:color';
// PROGRESS BARS
// -------------


// ANIMATIONS
// ----------

@keyframes progress-bar-stripes {
  from  { background-position: 40px 0; }
  to    { background-position: 0 0; }
}


// THE BARS
// --------

// Outer container
.progress {
  overflow: hidden;
  height: 22px;
  @include mixins.gradient-vertical(#f5f5f5, #f9f9f9);
  @include mixins.box-shadow(inset 0 1px 2px rgba(0,0,0,.1));
  @include mixins.border-radius(4px);
}

// Bar of progress
.progress .bar {
  width: 0%;
  min-width: 20px;
  height: 22px;
  color: colors.$white;
  font-size: 12px;
  text-align: center;
  text-shadow: 0 -1px 0 rgba(0,0,0,.25);
  @include mixins.gradient-vertical(#149bdf, #0480be);
  @include mixins.box-shadow(inset 0 -1px 0 rgba(0,0,0,.15));
  @include mixins.box-sizing(border-box);
  @include mixins.transition(width .6s ease);
}

// Striped bars
.progress-striped .bar {
  @include mixins.gradient-striped(#149bdf);
  @include mixins.background-size(40px 40px);
}

// Call animation for the active one
.progress.active .bar {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
     -moz-animation: progress-bar-stripes 2s linear infinite;
      -ms-animation: progress-bar-stripes 2s linear infinite;
       -o-animation: progress-bar-stripes 2s linear infinite;
          animation: progress-bar-stripes 2s linear infinite;
}



// COLORS
// ------

// Danger (red)
.progress-danger .bar {
  @include mixins.gradient-vertical(#ee5f5b, #c43c35);
}
.progress-danger.progress-striped .bar {
  @include mixins.gradient-striped(#ee5f5b);
}

// Success (green)
.progress-success .bar {
  @include mixins.gradient-vertical(#62c462, #57a957);
}
.progress-success.progress-striped .bar {
  @include mixins.gradient-striped(#62c462);
}

// Info (teal)
.progress-info .bar {
  @include mixins.gradient-vertical(#5bc0de, #339bb9);
}
.progress-info.progress-striped .bar {
  @include mixins.gradient-striped(#5bc0de);
}

// Warning (orange)
.progress-warning .bar {
  @include mixins.gradient-vertical(color.adjust(bootstrap-variables.$orange, $lightness: 15%), bootstrap-variables.$orange);
}

.progress-warning.progress-striped .bar {
  @include mixins.gradient-striped(color.adjust(bootstrap-variables.$orange, $lightness: 15%));
}
