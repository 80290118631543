@mixin break-all {
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word; /* Non standard for webkit */
}

@mixin vendor-prefix($name, $argument) {
  -webkit-#{$name}: #{$argument};
  -ms-#{$name}: #{$argument};
  -moz-#{$name}: #{$argument};
  -o-#{$name}: #{$argument};
  #{$name}: #{$argument};
}

@mixin vertically-center {
  display: inline-block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@mixin clearfix {
  &:after {
    clear: both;
    content: '';
    display: table;
  }
}
