@use 'sass:color';
@use 'sass:math';
// Forms.less
// Base styles for various input types, form layouts, and states
// -------------------------------------------------------------

@use 'colors';
@use "bootstrap-variables";
@use "mixins";

// GENERAL STYLES
// --------------

// Make all forms have space below them
form {
  margin: 0 0 bootstrap-variables.$baseLineHeight;
}

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}

// Groups of fields with labels on top (legends)
legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: bootstrap-variables.$baseLineHeight * 1.5;
  font-size: bootstrap-variables.$baseFontSize * 1.5;
  line-height: bootstrap-variables.$baseLineHeight * 2;
  color: bootstrap-variables.$grayDark;
  border: 0;
  border-bottom: 1px solid #e5e5e5;

  // Small
  small {
    font-size: bootstrap-variables.$baseLineHeight * .75;
    color: bootstrap-variables.$grayLight;
  }
}

// Set font for forms
label, input, button, select, textarea {
  @include mixins.font-shorthand(bootstrap-variables.$baseFontSize, normal, bootstrap-variables.$baseLineHeight); // Set size, weight, line-height here
}
input, button, select, textarea {
  font-family: bootstrap-variables.$baseFontFamily, 'Font Awesome 5 Pro'; // And only set font-family here for those that need it (note the missing label element)
}

// Identify controls by their labels
label {
  display: block;
  margin-bottom: 5px;
}

// Form controls
// -------------------------

// Shared size and type resets
select, textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"],
input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"],
input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"], .uneditable-input {
  display: inline-block;
  height: bootstrap-variables.$baseLineHeight;
  padding: 4px;
  margin-bottom: 9px;
  font-size: bootstrap-variables.$baseFontSize;
  line-height: bootstrap-variables.$baseLineHeight;
  color: colors.$gray700;
}

// Reset appearance properties for textual inputs and textarea
// Declare width for legacy (can't be on input[type=*] selectors or it's too specific)
input, textarea {
  width: 210px;
}
// Reset height since textareas have rows
textarea {
  height: auto;
}
// Everything else
textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"],
input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"],
input[type="url"], input[type="search"], input[type="tel"], input[type="color"], .uneditable-input {
  background-color: bootstrap-variables.$inputBackground;
  border: 1px solid bootstrap-variables.$inputBorder;
  @include mixins.border-radius(bootstrap-variables.$inputBorderRadius);
  @include mixins.box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
  @include mixins.transition(#{border linear .2s, box-shadow linear .2s});

  // Focus state
  &:focus {
    border-color: rgba(82,168,236,.8);
    outline: 0;
    @include mixins.box-shadow(#{inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6)});
  }
}

// Position radios and checkboxes better
input[type="radio"], input[type="checkbox"] {
  margin: 3px 0;
  line-height: normal;
  cursor: pointer;
}

// Reset width of input buttons, radios, checkboxes
input[type="submit"], input[type="reset"], input[type="button"], input[type="radio"], input[type="checkbox"] {
  width: auto; // Override of generic input selector
}

// Make uneditable textareas behave like a textarea
.uneditable-textarea {
  width: auto;
  height: auto;
}

// Set the height of select and file controls to match text inputs
select, input[type="file"] {
  height: 28px; /* In IE7, the height of the select element cannot be changed by height, only font-size */
  line-height: 28px;
}

// Make select elements obey height by applying a border
select {
  width: 220px; // default input width + 10px of padding that doesn't get applied
  border: 1px solid #bbb;
}

// Make multiple select elements height not fixed
select[multiple], select[size] {
  height: auto;
}

// Focus for select, file, radio, and checkbox
select:focus, input[type="file"]:focus, input[type="radio"]:focus, input[type="checkbox"]:focus {
  @include mixins.tab-focus();
}



// CHECKBOXES & RADIOS
// -------------------

// Indent the labels to position radios/checkboxes as hanging
.radio, .checkbox {
  min-height: 18px; // clear the floating input if there is no label text
  padding-left: 18px;
}
.radio input[type="radio"], .checkbox input[type="checkbox"] {
  float: left;
  margin-left: -18px;
}

// Move the options list down to align with labels
.controls > .radio:first-child, .controls > .checkbox:first-child {
  padding-top: 5px; // has to be padding because margin collaspes
}

// Radios and checkboxes on same line
// TODO v3: Convert .inline to .control-inline
.radio.inline, .checkbox.inline {
  display: inline-block;
  padding-top: 5px;
  margin-bottom: 0;
  vertical-align: middle;
}
.radio.inline + .radio.inline, .checkbox.inline + .checkbox.inline {
  margin-left: 10px; // space out consecutive inline controls
}



// INPUT SIZES
// -----------

// General classes for quick sizes
.input-mini       { width: 60px; }
.input-small      { width: 90px; }
.input-medium     { width: 150px; }
.input-large      { width: 210px; }
.input-xlarge     { width: 270px; }
.input-xxlarge    { width: 530px; }

// Grid style input sizes
input[class*="span"], select[class*="span"], textarea[class*="span"], .uneditable-input[class*="span"],
// Redeclare since the fluid row class is more specific
.row-fluid input[class*="span"], .row-fluid select[class*="span"], .row-fluid textarea[class*="span"], .row-fluid .uneditable-input[class*="span"] {
  float: none;
  margin-left: 0;
}
// Ensure input-prepend/append never wraps
.input-append input[class*="span"], .input-append .uneditable-input[class*="span"], .input-prepend input[class*="span"],
.input-prepend .uneditable-input[class*="span"], .row-fluid .input-prepend [class*="span"], .row-fluid .input-append [class*="span"] {
  display: inline-block;
}



// GRID SIZING FOR INPUTS
// ----------------------

@include mixins.gridInput(bootstrap-variables.$gridColumnWidth, bootstrap-variables.$gridGutterWidth);


// DISABLED STATE
// --------------

// Disabled and read-only inputs
input[disabled], select[disabled], textarea[disabled], input[readonly], select[readonly], textarea[readonly] {
  cursor: not-allowed;
  background-color: bootstrap-variables.$inputDisabledBackground;
  border-color: #ddd;
}
// Explicitly reset the colors here
input[type="radio"][disabled], input[type="checkbox"][disabled], input[type="radio"][readonly], input[type="checkbox"][readonly] {
  background-color: transparent;
}

// FORM FIELD FEEDBACK STATES
// --------------------------

// Warning
.control-group.warning {
  @include mixins.formFieldState(bootstrap-variables.$warningText, bootstrap-variables.$warningText, bootstrap-variables.$warningBackground);
}
// Error
.control-group.error {
  @include mixins.formFieldState(bootstrap-variables.$errorText, bootstrap-variables.$errorText, bootstrap-variables.$errorBackground);
}
// Success
.control-group.success {
  @include mixins.formFieldState(bootstrap-variables.$successText, bootstrap-variables.$successText, bootstrap-variables.$successBackground);
}

// HTML5 invalid states
// Shares styles with the .control-group.error above
input:focus:required:invalid, textarea:focus:required:invalid, select:focus:required:invalid {
  color: #b94a48;
  border-color: #ee5f5b;
  &:focus {
    border-color: color.adjust(#ee5f5b, $lightness: -10%);
    @include mixins.box-shadow(0 0 6px color.adjust(#ee5f5b, $lightness: 20%));
  }
}


// FORM ACTIONS
// ------------

.form-actions {
  padding: (bootstrap-variables.$baseLineHeight - 1) 20px bootstrap-variables.$baseLineHeight;
  margin-top: bootstrap-variables.$baseLineHeight;
  margin-bottom: bootstrap-variables.$baseLineHeight;
  background-color: bootstrap-variables.$formActionsBackground;
  border-top: 1px solid #e5e5e5;
  @include mixins.clearfix(); // Adding clearfix to allow for .pull-right button containers
}

// For text that needs to appear as an input but should not be an input
.uneditable-input {
  overflow: hidden; // prevent text from wrapping, but still cut it off like an input does
  white-space: nowrap;
  cursor: not-allowed;
  background-color: bootstrap-variables.$inputBackground;
  border-color: #eee;
  @include mixins.box-shadow(inset 0 1px 2px rgba(0,0,0,.025));
}

// Placeholder text gets special styles; can't be bundled together though for some reason
// (sass-edit) - need special mixin
@include mixins.rootPlaceholder();



// HELP TEXT
// ---------

.help-block, .help-inline {
  color: bootstrap-variables.$gray; // lighten the text some for contrast
}

.help-block {
  display: block; // account for any element using help-block
  margin-bottom: math.div(bootstrap-variables.$baseLineHeight, 2);
}

.help-inline {
  display: inline-block;
  vertical-align: middle;
  padding-left: 5px;
}



// INPUT GROUPS
// ------------

// Allow us to put symbols and text within the input field for a cleaner look
.input-prepend, .input-append {
  margin-bottom: 5px;
  input, select, .uneditable-input {
    position: relative; // placed here by default so that on :focus we can place the input above the .add-on for full border and box-shadow goodness
    margin-bottom: 0; // prevent bottom margin from screwing up alignment in stacked forms
    *margin-left: 0;
    vertical-align: middle;
    @include mixins.border-radius(0 bootstrap-variables.$inputBorderRadius bootstrap-variables.$inputBorderRadius 0);
    // Make input on top when focused so blue border and shadow always show
    &:focus {
      z-index: 2;
    }
  }
  .uneditable-input {
    border-left-color: #ccc;
  }
  .add-on {
    display: inline-block;
    width: auto;
    height: bootstrap-variables.$baseLineHeight;
    min-width: 16px;
    padding: 4px 5px;
    font-weight: normal;
    line-height: bootstrap-variables.$baseLineHeight;
    text-align: center;
    text-shadow: 0 1px 0 colors.$white;
    vertical-align: middle;
    background-color: bootstrap-variables.$grayLighter;
    border: 1px solid #ccc;
  }
  .add-on, .btn {
    margin-left: -1px;
    @include mixins.border-radius(0);
  }
  .active {
    background-color: color.adjust(bootstrap-variables.$green, $lightness: 30%);
    border-color: bootstrap-variables.$green;
  }
}
.input-prepend {
  .add-on, .btn {
    margin-right: -1px;
  }
  .add-on:first-child, .btn:first-child {
    @include mixins.border-radius(bootstrap-variables.$inputBorderRadius 0 0 bootstrap-variables.$inputBorderRadius);
  }
}
.input-append {
  input, select, .uneditable-input {
    @include mixins.border-radius(bootstrap-variables.$inputBorderRadius 0 0 bootstrap-variables.$inputBorderRadius);
  }
  .uneditable-input {
    border-right-color: #ccc;
    border-left-color: #eee;
  }
  .add-on:last-child, .btn:last-child {
    @include mixins.border-radius(0 bootstrap-variables.$inputBorderRadius bootstrap-variables.$inputBorderRadius 0);
  }
}
// Remove all border-radius for inputs with both prepend and append
.input-prepend.input-append {
  input, select, .uneditable-input {
    @include mixins.border-radius(0);
  }
  .add-on:first-child, .btn:first-child {
    margin-right: -1px;
    @include mixins.border-radius(bootstrap-variables.$inputBorderRadius 0 0 bootstrap-variables.$inputBorderRadius);
  }
  .add-on:last-child, .btn:last-child {
    margin-left: -1px;
    @include mixins.border-radius(0 bootstrap-variables.$inputBorderRadius bootstrap-variables.$inputBorderRadius 0);
  }
}



// SEARCH FORM
// -----------

.search-query {
  padding-right: 14px;
  padding-left: 14px;
  margin-bottom: 0; // remove the default margin on all inputs
  @include mixins.border-radius(14px);
}



// HORIZONTAL & VERTICAL FORMS
// ---------------------------

// Common properties
// -----------------

.form-search, .form-inline, .form-horizontal {
  input, textarea, select, .help-inline, .uneditable-input, .input-prepend, .input-append {
    display: inline-block;
    margin-bottom: 0;
  }
  // Re-hide hidden elements due to specifity
  .hide {
    display: none;
  }
}
.form-search label, .form-inline label {
  display: inline-block;
}
// Remove margin for input-prepend/-append
.form-search .input-append, .form-inline .input-append, .form-search .input-prepend, .form-inline .input-prepend {
  margin-bottom: 0;
}
// Inline checkbox/radio labels (remove padding on left)
.form-search .radio, .form-search .checkbox, .form-inline .radio, .form-inline .checkbox {
  padding-left: 0;
  margin-bottom: 0;
  vertical-align: middle;
}
// Remove float and margin, set to inline-block
.form-search .radio input[type="radio"], .form-search .checkbox input[type="checkbox"],
.form-inline .radio input[type="radio"], .form-inline .checkbox input[type="checkbox"] {
  float: left;
  margin-right: 3px;
  margin-left: 0;
}


// Margin to space out fieldsets
.control-group {
  margin-bottom: math.div(bootstrap-variables.$baseLineHeight, 2);
}

// Legend collapses margin, so next element is responsible for spacing
legend + .control-group {
  margin-top: bootstrap-variables.$baseLineHeight;
  -webkit-margin-top-collapse: separate;
}

// Horizontal-specific styles
// --------------------------

.form-horizontal {
  // Increase spacing between groups
  .control-group {
    margin-bottom: bootstrap-variables.$baseLineHeight;
    @include mixins.clearfix();
  }
  // Float the labels left
  .control-label {
    float: left;
    width: 140px;
    padding-top: 5px;
    text-align: right;
  }
  // Move over all input controls and content
  .controls {
    margin-left: 160px;
  }
  // Remove bottom margin on block level help text since that's accounted for on .control-group
  .help-block {
    margin-top: math.div(bootstrap-variables.$baseLineHeight, 2);
    margin-bottom: 0;
  }
  // Move over buttons in .form-actions to align with .controls
  .form-actions {
    padding-left: 160px;
  }
}
