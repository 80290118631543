@use 'colors';
@use 'theme';
@use 'sizes';

.sidebar {
  background-color: colors.$blue500;
  bottom: 0;
  left: 0;
  padding-top: 60px;
  position: fixed;
  top: 0;
  width: sizes.$sidebar-width;


  font-family: 'Roboto', 'helvetica neue', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased; // subpixel-antialiasing messes up our font weights
  -webkit-font-feature-settings: 'kern' 1, 'liga' 1; // ensures we use available opentype features
}

.sidebar-item {
  display: block;
  opacity: 0.8;
  padding-bottom: 13px;
  padding-top: 13px;
  text-decoration: none; // these are <a>s

  &:hover {
    opacity: 1;
    text-decoration: none;
  }

  /* Make dashboard and apps icons sharp. Remove when reexported (viewBox='0 0 26 26') */
  &.apps img,
  &.dashboards img {
    height: 21px;
  }

  > img {
    box-sizing: border-box;
    display: block;
    height: 26px;
    margin: 0 auto;
  }

  > span {
    color: white;
    display: block;
    float: none; // because bootstrap uses [class*=] which is awful
    font-size: 10px;
    line-height: 1.1; // to account for 'Bill Meters', which wraps :(
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 5px;
    text-align: center;
  }
}
