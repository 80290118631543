@use "sass:math";

@use 'bootstrap-variables';
@use 'sizes';
@use 'colors';
@use 'shadows';

.modal {
  box-shadow: 0 10px 16px -2px rgba(0, 0, 0, 0.5);
  margin: -250px 0 0 (-280px + math.div(sizes.$sidebar-width, 2));
  max-height: 100vh;
  top: 0px;
}

.modal.in {
  top: 10%;
  margin-top: 0;
}

.modal.fade.in {
  top: 10%;
}

.modal-body {
  margin-left: 20px;
  margin-right: 20px;
  max-height: calc(80vh - 140px);
  padding: 0 0 20px;
  overflow: auto;
}

.modal-content {
  margin-right: 20px;
}

.modal-large-switcher {
  font-size: 18px;
  max-width: 526px;
}

.modal-text-separator-body {
  height: 15px;
  margin-top: -11px;
}

.modal-text-separator {
  font-size: 21px;
  margin-left: 2px;
}

.modal-body-thumbs {
  background: #eee;
}

.modal-header {
  border-bottom: 0 solid #eee;
  padding: 25px 15px;

  .close {
    font-size: 3em;
  }
}

.modal-header-buttons {
  border-bottom: 1px solid colors.$gray300;
  margin-bottom: 16px;
  padding: 16px;

  h3 {
    font-size: 18px;
    line-height: 36px;
  }

  button {
    margin-left: 8px;
  }
}

.modal-footer {
  background-color: #f9f9f9;
  border-top: none;

  button {
    margin: 0 10px;
  }
  a {
    cursor: pointer;
  }
}

.modal-invite {
  width: 560px;
}

.modal-invite-body {
  padding: 15px;
}

.modal500 {
  padding: 40px;
  padding-top: 0;
}

/* modal forms */
.modal .span2 {
  width: 105px;
  min-width: 0;
}

.modal .span4 {
  margin-left: 0;
  width: 220px;
  min-width: 0;
}

.modal.fat {
  left: 40%;
  width: 760px;
}

.modal .ms-custom-header {
  width: 230px;
}

.modal legend {
  font-size: 1.2em;
}

$modal-widest-width: 960px;
$modal-widest-sidebar-width: 250px;

.modal.widest {
  margin-left: math.div(-$modal-widest-width + sizes.$sidebar-width, 2);
  width: $modal-widest-width;
}

.modal-body.with-sidebar {
  border-bottom: 1px solid colors.$gray400;
  border-top: 1px solid colors.$gray400;
  margin: 0;
  padding: 0;
}

.ModalLayout {
  display: flex;
}

.ModalSidebar {
  background: colors.$gray200;
  box-sizing: border-box;
  flex: 220px;
  padding: 20px;
}

.ModalContent {
  box-sizing: border-box;
  flex: 100%;
  overflow: auto;
  height: calc(67vh - 58px);
  max-height: calc(67vh - 1vh);

  &--padded {
    padding: 20px;
  }

  &--half-padded {
    padding: 10px;
  }
}

.ModalStep {
  &--done,
  &--future {
    color: colors.$gray500;
  }

  &--future {
    .ModalStepNumber {
      background: none;
      border: 1px solid colors.$gray500;
      color: colors.$gray500;
    }
  }
}

.ModalStepNumber {
  background-color: colors.$blue400;
  border-radius: 20px;
  color: white;
  display: inline-block;
  height: 20px;
  margin-bottom: 20px;
  margin-right: 10px;
  text-align: center;
  width: 20px;
}
$grid-item-width: 180px;
.ReportTypeSelectorGrid {
  position: relative;
  // first 4 items
  &:nth-child(-n+4) {
    border-top-color: colors.$gray300;
  }
  // each 4th item, starting at 1st
  &:nth-child(4n+1) {
    border-left-color: colors.$gray300;
  }

  &:hover {
    border-color: colors.$blue300;
    box-shadow: 0 0 0 1px colors.$blue300 inset;
    color: inherit;
    text-decoration: none;
    .tooltiptext {
      visibility: visible;
    }
  }

  & {
    border: 1px solid transparent;
    border-bottom-color: colors.$gray300;
    border-right-color: colors.$gray300;
    box-sizing: border-box;
    height: 160px;
    padding: 0 22px 22px;
    width: $grid-item-width;
  }

  .tooltiptext {
    visibility: hidden;
    width: $grid-item-width - 20px;
    background-color: colors.$gray700;
    color: bootstrap-variables.$white;
    padding: 10px;
    position: absolute;
    z-index: 1;
    left: 10px;
    font-size: 12px;
    bottom: 154px;
    box-shadow: colors.$dropdownMenuBoxShadow;
    box-sizing: border-box;
  }

  .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    border: 10px solid transparent; 
    border-top-color: colors.$gray700;
  }

  img {
    display: block;
    margin: 10px auto;
  }

  h4 {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 16px;
  }
}

.ReportTypeSelectorList {
  &:hover {
    text-decoration: none;

    > span {
      background-color: colors.$gray100;
      box-shadow: shadows.$button-box-shadow-hover;
      color: colors.$blue300;

      svg {
        stroke: colors.$blue300;
      }
    }
  }

  & {
    border-radius: 5px;
    box-shadow: 0 0 2px 0 rgba(17, 25, 31, 0.12), 0 2px 2px 0 rgba(17, 25, 31, 0.12);
    cursor: pointer;
    display: block;
    line-height: 64px;
    margin-bottom: 10px;
  }

  > * {
    display: inline-block;
  }

  > img {
    height: 40px;
    margin: 0 23px;
    vertical-align: middle;
  }
  // TODO: extract Button mixin
  > span {
    border: 1px solid colors.$gray400;
    border-radius: 5px;
    float: right;
    line-height: 0.5;
    margin: 13px;
    padding: 1px 12px 12px;

    svg {
      left: -5px;
      position: relative;
      top: 5px;
    }
  }
}

.FormControl {
  width: calc(100% - 40px);

  input[type=text] {
    width: 100%;
  }

  input[disabled] {
    color: colors.$gray500;
  }
}

.FormRadioGroup {
  label:first-child {
    font-weight: bold;
    margin-bottom: 5px;
  }

  label {
    display: block;
    font-weight: normal;
    line-height: 23px;

    input[type=radio] {
      margin: -3px 4px 0 2px;
    }
  }
}

.FormHintPanel {
  color: colors.$gray500;
  margin: 16px 0;
}

.FormRow {
  &--hidden{
    display: none;
  }

  input,
  textarea {
    border-radius: 5px;
    box-shadow: none;
    padding: 11px !important;
  }

  select {
    appearance: none;
    background-color: bootstrap-variables.$white;
    border-radius: 5px;
    box-sizing: content-box;
    min-width: 220px;
    padding: 3px 11px;

    // appearance: none for IE
    &::-ms-expand {
      display: none;
    }
  }

  .secondField select {
    min-width: initial;
  }

  select.span4 {
    min-width: 200px;
    padding: 3px 30px 3px 11px;
    width: 200px;
  }

  textarea {
    min-width: 220px;
  }

  .input-prepend,
  .input-append {
    input,
    select {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }

  .input-prepend .add-on,
  .input-append .add-on {
    height: 30px;
    line-height: 30px;
  }
}

.FormLabelSelect {
  &:hover select {
    box-shadow: shadows.$button-box-shadow-hover;
  }

  &:hover [data-icon=dropdown] {
    stroke: colors.$blue300;
  }

  & {
    display: inline-block;
    position: relative;
  }

  [data-icon=dropdown] {
    pointer-events: none;
    position: absolute;
    right: 8px;
    top: calc(50% - 12px);
  }

  .select2-chosen {
    font-weight: normal;
  }

  [readonly], [disabled] {
    background-color: bootstrap-variables.$inputDisabledBackground;
  }

  /* make room for the icon, preventing long options from running into it */
  select {
    min-width: 206px;
    width: 206px;
    padding-right: 25px;
  }
}

$num-tabs: 2;
$tab-height: 60;
$tab-height-unit: px;
$tab-width: 32;
$tab-width-unit: px;
$tab-position: right;
$tab-top: 8px;

.Tabs {
  .TabSelector:checked + .TabTitle {
    background-color: colors.$gray200;
    border-color: colors.$gray600;
  }

  .TabSelector:checked + .TabTitle + .TabContent {
    display: flex;
    &.TabGrid { 
      flex-wrap: wrap;
    }
    &.TabList { 
      flex-direction: column;
    }
  }

  > *:first-child .TabTitle {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
  }

  > *:last-child .TabTitle {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  }

  & {
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-top: #{ $tab-height }#{ $tab-height-unit };
    position: relative;
  }

  @for $i from 1 through $num-tabs {
    > *:nth-child(#{ $i }) .TabTitle {
      #{ $tab-position }: #{ $tab-width * ($i - 1) }#{ $tab-width-unit };
    }
  }

  .TabSelector {
    position: absolute;
    top: -999px;
  }

  .TabSelector + .TabTitle {
    // TODO: extract Button--tab mixin
    border: 1px solid colors.$gray300;
    cursor: pointer;
    position: absolute;
    top: $tab-top;

    img {
      margin: 4px;
    }
  }

  .TabSelector + .TabTitle + .TabContent {
    display: none;
  }
}

/* the categorize modal has a tooltip which extends past
the top of the page unless given more top offset */
#categorizeModal {
  top: 15%;

  .modal-body {
    max-height: calc(70vh - 140px);
  }
}

/*
Styling of Select2 dropdown in report configuration modal.
*/
.modal {
  .select2-container-multi {
    width: 243px;

    ul.select2-choices {
      background-image: none;
      border: 1px solid #bbb;
      border-radius: 5px;
      padding-top: 3px;

      li.select2-search-choice {
        background-image: none;
        background-color: colors.$gray300;
        border: none;
        border-radius: 16px;
        box-shadow: none;
        color: colors.$gray900;
        display: inline-flex;
        font-size: 12px;
        line-height: unset;
        padding: 6px 15px;
        text-decoration: none;
        vertical-align: middle;

        a.select2-search-choice-close {
          color: colors.$gray400;
          position: relative;
          top: 3px;
        }
      }
    }
  }

  .select2-container-multi.select2-dropdown-open {
    ul.select2-choices {
      border-radius: 5px 5px 0 0;
    }
  }
  .select2-container-multi.select2-dropdown-open.select2-drop-above {
    ul.select2-choices {
      border-radius: 0 0 5px 5px;
    }
  }
}

#createReport {
 @media (max-width: 1040px) {
   width: 98vw;
   left: 452px;
 }
}
