@use 'colors';
@use "bootstrap-variables";

/*
*  .container - augment Bootstrap container until eliminated
*  .ContainerHeader - wraps page header, capped at 1600px
*  .ContainerShade - wraps .ContainerMain full-width, shaded background
*  .ContainerMain - wraps page main content, capped at 1600px
*  Various container utility classes - .Container--*
*/

.notification-bubble {
  background: colors.$red300;
  border: 2px solid bootstrap-variables.$white;
  border-radius: 15px;
  box-sizing: border-box;
  color: bootstrap-variables.$white;
  display: block;
  font-size: 14px;
  font-weight: 500;
  height: 25px;
  line-height: 20px;
  min-width: 25px;
  padding: 0 7px;
  position: absolute;
  right: -10px;
  text-align: center;
  top: -10px;

  &.disabled {
    background: colors.$gray300;
    background: linear-gradient(to bottom, colors.$gray300, colors.$gray400);
  }

  &.beta-bubble {
    background: colors.$gray500;

    &.has-notifications {
      right: 16px;
    }
  }
}

.container {
  padding-top: 20px;
}

.ContainerHeader,
.ContainerMain {
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
}

.ContainerHeader {
  > div {
    margin: 0 23px;

    /* TODO extract constants. 1600 (capped width) + 2*23 (header padding) + 72 (sidebar width) */
    @media (min-width: 1718px) {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  @media (max-width: 1717px) {
    margin-left: 0;
    margin-right: 0;
  }

  > .arrange,
  > .flex {
    margin: 60px 23px 18px;
    min-width: auto;

    .page-header {
      margin: 0;
    }

    nav {
      margin-bottom: -13px;
      margin-left: -23px;
    }
  }

  > .flex {
    display: flex;
    flex-wrap: wrap;
  }

  .flex-grow {
    flex-grow: 1;
  }
}

.ContainerShade {
  background-color: colors.$gray200;
  min-height: calc(100vh - 370px);
}

.ContainerShade--noMinHeight {
  min-height: auto;
}

.ContainerMain {
  padding: 23px;
  padding-top: 24px;
  position: relative;

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
            
  &.ContainerMain--no-padding {
    padding: 0;
    @media (max-width: 1713px){
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.ContainerMain--no-bottom-padding {
    padding-bottom: 0;
  }

  &.ContainerMain--no-transition .Card {
    transition: none;
  }

  .infoRow {
    color: colors.$gray600;
    height: 12px;
    overflow: visible;
    position: relative;
    top: -16px;
    white-space: nowrap;
  }

  .fluidGrid.fluidGrid--wideCells > div {
    height: 480px;

    > a {
      height: 479px;
    }
  }

  .fluidGrid.fluidGrid--height220 > div {
    height: 220px;

    > a {
      height: 219px;
    }
  }

  .fluidGrid > div {
    background-color: bootstrap-variables.$white;
    border: 1px solid colors.$gray300;
    height: 265px;

    a {
      border: 2px solid transparent;
      display: block;
      height: 264px;
      padding: 23px 25px;

      &:hover {
        border: 2px solid colors.$blue300;
        color: colors.$gray800;
        text-decoration: none;
      }

      & > .avatar-container {
        height: 100px;
        margin: 0 auto;
        position: relative;
        width: 100px;

        & > img {
          border-radius: 10px;
          height: 100%;
          width: 100%;
        }
      }

      & > h3 {
        -webkit-box-orient: vertical;
        display: -webkit-box;
        font-size: 16px;
        font-weight: 300;
        -webkit-line-clamp: 2;
        line-height: 22px;
        max-height: 44px;
        overflow: hidden;
      }

      & > ul {
        color: colors.$gray500;
        font-size: 12px;
        font-weight: 300;
        margin: 8px 0 0 0;
      }

      li {
        line-height: 1.5;
      }
    }
  }

  table {
    background-color: bootstrap-variables.$white;
    border: 1px solid colors.$gray300;
  }

  td {
    background-color: bootstrap-variables.$white;
  }
}

.ContainerScrollable {
  overflow: scroll;
}

.Container--borderTop {
  border-top: 4px solid colors.$gray300;
}

.Container--borderBottom {
  border-bottom: 4px solid colors.$gray300;
}

.Container--minHeight {
  min-height: calc(100vh - 370px);
}

.ZeroState {
  padding-top: 37px;

  img {
    margin-bottom: 30px;
  }
  p {
    font-weight: 900;	    
    margin-bottom: 22px;
  }
}
