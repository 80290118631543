@use "bootstrap-variables";
@use "mixins";

//
// Layouts
// Fixed-width and fluid (with sidebar) layouts
// --------------------------------------------


// Container (centered, fixed-width layouts)
.container {
  @include mixins.container-fixed();
}

// Fluid layouts (left aligned, with sidebar, min- & max-width content)
.container-fluid {
  padding-right: bootstrap-variables.$gridGutterWidth;
  padding-left: bootstrap-variables.$gridGutterWidth;
  @include mixins.clearfix();
}