@use "bootstrap-variables";
@use "colors";
@use "mixins";

@use 'sass:color';
// LABELS & BADGES
// ---------------

// Base classes
.label, .badge {
  font-size: bootstrap-variables.$baseFontSize * .846;
  font-weight: bold;
  line-height: 14px; // ensure proper line-height if floated
  color: colors.$white;
  vertical-align: baseline;
  white-space: nowrap;
  text-shadow: 0 -1px 0 rgba(0,0,0,.25);
  background-color: bootstrap-variables.$grayLight;
}
// Set unique padding and border-radii
.label {
  padding: 1px 4px 2px;
  @include mixins.border-radius(3px);
}
.badge {
  padding: 1px 9px 2px;
  @include mixins.border-radius(9px);
}

// Hover state, but only for links
a {
  &.label:hover, &.badge:hover {
    color: colors.$white;
    text-decoration: none;
    cursor: pointer;
  }
}

// Colors
// Only give background-color difference to links (and to simplify, we don't qualifty with `a` but [href] attribute)
// Important (red)
.label-important, .badge-important               { background-color: bootstrap-variables.$errorText; }
.label-important[href], .badge-important[href]   { background-color: color.adjust(bootstrap-variables.$errorText, $lightness: -10%); }
// Warnings (orange)
.label-warning, .badge-warning                 { background-color: bootstrap-variables.$orange; }
.label-warning[href], .badge-warning[href]     { background-color: color.adjust(bootstrap-variables.$orange, $lightness: -10%); }
// Success (green)
.label-success, .badge-success                 { background-color: bootstrap-variables.$successText; }
.label-success[href], .badge-success[href]     { background-color: color.adjust(bootstrap-variables.$successText, $lightness: -10%); }
// Info (turquoise)
.label-info, .badge-info                 { background-color: bootstrap-variables.$infoText; }
.label-info[href], .badge-info[href]     { background-color: color.adjust(bootstrap-variables.$infoText, $lightness: -10%); }
// Inverse (black)
.label-inverse, .badge-inverse                { background-color: bootstrap-variables.$grayDark; }
.label-inverse[href], .badge-inverse[href]    { background-color: color.adjust(bootstrap-variables.$grayDark, $lightness: -10%); }
