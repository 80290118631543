@use 'colors';

#env {
    background: #000;
    color: #f00;
    font-size: 17px;
    font-weight: 500;
    left: 0;
    line-height: 1.5;
    padding-left: 9px;
    position: fixed;
    top: 0;
    z-index: 5;

    font-family: 'Roboto', 'helvetica neue', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased; // subpixel-antialiasing messes up our font weights
    -webkit-font-feature-settings: 'kern' 1, 'liga' 1; // ensures we use available opentype features
  }
  
  #env a {
    background: #000;
    color: #f00;
    font-size: 10px;
  }

/* push the popover down and widen so all the contents are easily visible */
.build-info {
  top: 0 !important;
}
.build-info .popover-inner {
  max-width: none;
}
.build-info a {
  color: colors.$gray900;
  text-decoration: none;

  &:hover {
    color: colors.$blue300;
  }
}
.build-info pre {
  background-color: #eee;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 7.5px;
  margin: 0 0 8px;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  font-size: 12.025px;
  line-height: 16px;
  word-break: break-all;
  word-wrap: break-word;
}

