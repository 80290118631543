@use 'sass:color';

// Variables.less
// Variables to customize the look and feel of Bootstrap
// -----------------------------------------------------



// GLOBAL VALUES
// --------------------------------------------------

// Grays
// -------------------------
$black:                 #111B1F !default;
$grayDarker:            #1F323A !default;
$grayDark:              #4D575B !default;
$gray:                  #97A1A5 !default;
$grayLight:             #E4EBEF !default;
$grayLighter:           #F9F9F9 !default;
$white:                 #fff !default;


// Accent colors
// ----------------------------
$blue:                  #126B96 !default;
$blueDark:              #09374D !default;
$green:                 #447A00 !default;
$red:                   #91281A !default;
$yellow:                #D99C00 !default;
$orange:                #D96800 !default;
$pink:                  #c3325f !default;
$purple:                #4C398C !default;

// Scaffolding
// -------------------------
$bodyBackground:        $white !default;
$textColor:             $black !default;

// Links
// -------------------------
$linkColor:             #126B96 !default;
$linkColorHover:        color.adjust($linkColor, $lightness: -15%) !default;

// Typography
// -------------------------
$sansFontFamily:        'Roboto', 'helvetica neue', Helvetica, Arial, sans-serif !default;
$serifFontFamily:       Georgia, "Times New Roman", Times, serif !default;
$monoFontFamily:        Menlo, Monaco, Consolas, "Courier New", monospace !default;

$baseFontSize:          13px !default;
$baseFontFamily:        $sansFontFamily !default;
$baseLineHeight:        16px !default;
$altFontFamily:         $serifFontFamily !default;

$headingsFontFamily:    inherit !default; // empty to use BS default, @baseFontFamily
$headingsFontWeight:    bold !default;    // instead of browser default, bold
$headingsColor:         inherit !default; // empty to use BS default, @textColor


// Tables
// -------------------------
$tableBackground:                   transparent !default; // overall background-color
$tableBackgroundAccent:             #f9f9f9 !default; // for striping
$tableBackgroundHover:              #f5f5f5 !default; // for hover
$tableBorder:                       #ddd !default; // table and cell border

// Buttons
// -------------------------
$btnBackground:                     $white !default;
$btnBackgroundHighlight:            color.adjust($white, $lightness: -10%) !default;
$btnBorder:                         #ccc !default;

$btnPrimaryBackground:              $linkColor !default;
$btnPrimaryBackgroundHighlight:     color.adjust($btnPrimaryBackground, $hue: 54deg) !default;

$btnInfoBackground:                 #5bc0de !default;
$btnInfoBackgroundHighlight:        #2f96b4 !default;

$btnSuccessBackground:              #62c462 !default;
$btnSuccessBackgroundHighlight:     #51a351 !default;

$btnWarningBackground:              color.adjust($orange, $lightness: 15%) !default;
$btnWarningBackgroundHighlight:     $orange !default;

$btnDangerBackground:               #ee5f5b !default;
$btnDangerBackgroundHighlight:      #bd362f !default;

$btnInverseBackground:              $gray !default;
$btnInverseBackgroundHighlight:     $grayDarker !default;

// Forms
// -------------------------
$inputBackground:               $white !default;
$inputBorder:                   #ccc !default;
$inputBorderRadius:             3px !default;
$inputDisabledBackground:       $grayLighter !default;
$formActionsBackground:         #f5f5f5 !default;


// Dropdowns
// -------------------------
$dropdownBackground:            $white !default;
$dropdownBorder:                rgba(0,0,0,.2) !default;
$dropdownLinkColor:             $grayDark !default;
$dropdownLinkColorHover:        $white !default;
$dropdownLinkBackgroundHover:   $linkColor !default;

$dropdownDividerTop:    #e5e5e5 !default;
$dropdownDividerBottom: $white !default;


// COMPONENT VARIABLES
// --------------------------------------------------

// Z-index master list
// -------------------------
// Used for a bird's eye view of components dependent on the z-axis
// Try to avoid customizing these :)
$zindexDropdown:          1000;
$zindexPopover:           1010;
$zindexTooltip:           1020;
$zindexFixedNavbar:       1030;
$zindexModalBackdrop:     1040;
$zindexModal:             1050;


// Input placeholder text color
// -------------------------
$placeholderText:         $grayLight !default;


// Hr border color
// -------------------------
$hrBorder:                $grayLighter !default;


// Navbar
// -------------------------
$navbarHeight:                    40px !default;
$navbarBackground:                $grayDarker !default;
$navbarBackgroundHighlight:       $grayDark !default;

$navbarText:                      $grayLight !default;
$navbarLinkColor:                 $grayLight !default;
$navbarLinkColorHover:            $white !default;
$navbarLinkColorActive:           $navbarLinkColorHover !default;
$navbarLinkBackgroundHover:       transparent !default;
$navbarLinkBackgroundActive:      $navbarBackground !default;

$navbarSearchBackground:          color.adjust($navbarBackground, $lightness: 25%) !default;
$navbarSearchBackgroundFocus:     $white !default;
$navbarSearchBorder:              color.adjust($navbarSearchBackground, $lightness: -30%) !default;
$navbarSearchPlaceholderColor:    #ccc !default;
$navbarBrandColor:                $navbarLinkColor !default;


// Hero unit
// -------------------------
$heroUnitBackground:              $grayLighter !default;
$heroUnitHeadingColor:            inherit !default;
$heroUnitLeadColor:               inherit !default;


// Form states and alerts
// -------------------------
$warningText:             #c09853 !default;
$warningBackground:       #fcf8e3 !default;
$warningBorder:           color.adjust($warningBackground, $lightness: -3%, $hue: -10deg) !default;

$errorText:               #b94a48 !default;
$errorBackground:         #f2dede !default;
$errorBorder:             color.adjust($errorBackground, $lightness: -3%, $hue: -10deg) !default;

$successText:             #468847 !default;
$successBackground:       #dff0d8 !default;
$successBorder:           color.adjust($successBackground, $lightness: -5%, $hue: -10deg) !default;

$infoText:                #3a87ad !default;
$infoBackground:          #d9edf7 !default;
$infoBorder:              color.adjust($infoBackground, $lightness: -7%, $hue: -10deg) !default;


// FIXED GRID
// --------------------------------------------------

// Default, 940px
// -------------------------
$gridColumns:             12 !default;
$gridColumnWidth:         54px !default;
$gridGutterWidth:         20px !default;
$gridRowWidth:            ($gridColumns * $gridColumnWidth) + ($gridGutterWidth * ($gridColumns - 1)) !default;

// Portrait tablet to default desktop
// -------------------------
$gridColumnWidthTablet:   42px !default;
$gridGutterWidthTablet:   20px !default;

// Large desktop and up
// -------------------------
$gridColumnWidthLarge:    70px !default;
$gridGutterWidthLarge:    30px !default;


// FLUID GRID
// --------------------------------------------------

// Default
// -------------------------
$fluidGridColumnWidth:         6.382978723% !default;
$fluidGridGutterWidth:         2.127659574% !default;

// Portrait tablet to default desktop
// -------------------------
$fluidGridColumnWidthTablet:   5.801104972% !default;
$fluidGridGutterWidthTablet:   2.762430939% !default;

// Large desktop and up
// -------------------------
$fluidGridColumnWidthLarge:    5.982905983% !default;
$fluidGridGutterWidthLarge:    2.564102564% !default;



// RESPONSIVE DESIGN
// --------------------------------------------------

// Media widths
// -------------------------
// Not supported Sass < 3.2 :(
// $mediaPhone:              480px !default;
// $mediaTablet:             768px !default;
// $mediaDesktop:            980px !default;
// $mediaLarge:              1200px !default; // and upper
