@use 'colors';

.entityCounter {
  border-left: 4px solid colors.$gray300;
  display: inline-block;
  margin-right: 42px;
  padding: 7px 10px;
  text-align: left;

  &:hover {
    text-decoration: none;

    .entityCounter-number {
      color: colors.$gray700;
    }

    .entityCounter-text {
      color: colors.$gray500;
    }
  }
}

.entityCounter-number {
  color: colors.$gray700;
  display: block;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
}

.entityCounter-text {
  color: colors.$gray700;
  display: block;
  line-height: 1;
  padding-top: 3px;
}
